import React, { useContext } from 'react'
import { Flex, Box, VStack } from '@chakra-ui/react'
import OrderCard from '../../common/OrderCard'
import { AuthContext } from '../../../context/AuthContext'
import DashboardNavbar from '../../common/DashboardNavbar'
export default function MyOrders({ selected }){

  const { OrdersArray, gridView } = useContext(AuthContext)

  return (
    <VStack
      h={'90%'}
      w={'93%'}
      maxW={'93%'}
    >
      {
       OrdersArray.length > 0 && <DashboardNavbar selected={selected} />
      }
      
      <Flex
        w={ gridView ? '100%' : '80%'}
        flexWrap={'wrap'}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        overflowY={'auto'}
        h={'60vh'}
      >
        {
          OrdersArray.length > 0 ? (
            <>
              {OrdersArray.map((item, index) => {
                return <OrderCard key={index} item={item} index={index} gridView={gridView} />
              })}
            </>) : (
            <Box
              fontSize={'md'}
              h={'60vh'} 
              color={'black'} w={'100%'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >No Orders</Box>
          )
        }
      </Flex>
    </VStack>

  )
}


import { Checkbox, HStack, Text, VStack, Box, Flex, Image, Button } from '@chakra-ui/react';
import React, { useState, useContext } from 'react';
import property_icon from '../../assets/add-single-property-icon.png';
import user_icon from '../../assets/user_icon.png';
import clock_icon from '../../assets/clock_icon.png';
import property_type_icon from '../../assets/property_type_icon.png';
import link_icon from '../../assets/link_icon.png';
import dots_icon from '../../assets/dots_icon.png';
import { AuthContext } from '../../context/AuthContext';
import { RiEdit2Line } from "react-icons/ri";
import { MdOutlineCloudDownload } from "react-icons/md";
export default function OrderCard(props) {
  const { FirstName, LastName, selectedArray, handleCardClick } = useContext(AuthContext);
  const [isHover, setisHover] = useState(false);
  const [showMenu, setshowMenu] = useState(false);

  const time = props.item.updatedAt;
  const formatDate = (time) => {
    const date = new Date(time);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = String(date.getUTCFullYear()).slice(-2);

    return `${day}/${month}/${year}`;
  };


  const formattedDate = formatDate(time);
  return (
    <>
      {props.gridView ? (
        <VStack
          gap={0}
          h={'332px'}
          w={'290px'}
          onMouseEnter={() => setisHover(true)}
          onMouseLeave={() => setisHover(false)}
          position={'relative'}
          py={'2'}
        >
          <VStack
            h={'272px'}
            w={'270px'}
            bg={'#FFFFFF'}
            rounded={14}
            p={3}
            justifyContent={'space-between'}
            alignItems={'center'}
            _hover={{
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 3px 10px',
              cursor: 'pointer',
            }}
            position={'relative'}
          >
            <HStack h={'25%'} w={'95%'} justifyContent={'flex-start'} alignItems={'flex-start'}>
              <Checkbox width={'10%'} mt={2}
              // isChecked={selectedArray.some(selectedCard => selectedCard._id === props.item._id)}
              // onChange={() => handleCardClick(props.item)}
              />
              <VStack h={'100%'} w={'90%'} justifyContent={'flex-start'} alignItems={'flex-start'} gap={1}>
                <Text fontSize={'smaller'} fontWeight={600}>
                  {props.item.address}
                </Text>
                <Text fontSize={'small'}>
                  {props.item.state}, {props.item.city}, {props.item.zipcode}
                </Text>
              </VStack>
              <Image src={dots_icon} alt=''
                onClick={() => setshowMenu(!showMenu)}
                mt={1}
              />
            </HStack>
            <VStack h={'72%'} w={'95%'} justifyContent={'space-between'} alignItems={'flex-start'}>
              <Flex gap={2}>
                <Image src={property_icon} alt='' w={'10%'} />
                <Text fontSize={'12px'}>Property ID: {props.item.apt_unit}</Text>
              </Flex>
              <HStack h={'20%'} w={'100%'} justifyContent={'space-between'} alignItems={'flex-start'}>
                <Flex gap={2} justifyContent={'flex-start'} h={'100%'} w={'50%'} alignItems={'center'}>
                  <Image src={clock_icon} alt='' w={'20%'} h={'60%'} />
                  <Text fontSize={'11px'}>
                    <Text>{formattedDate}</Text>
                  </Text>
                </Flex>
                <Flex gap={2} justifyContent={'flex-start'} h={'100%'} w={'50%'} alignItems={'center'}>
                  <Image src={property_type_icon} alt='' w={'20%'} h={'60%'} />
                  <Text fontSize={'12px'}>{props.item.type}</Text>
                </Flex>
              </HStack>
              <Flex gap={2}>
                <Image src={user_icon} alt='' w={4} />
                <Text fontSize={'11px'}>
                  {props.item.toclient ? `${FirstName} ${LastName}` : 'NA'}
                </Text>
              </Flex>
              <HStack w={'100%'} h={'30%'} justifyContent={'space-between'} alignItems={'center'}>
                <Flex gap={3}>
                  <Image src={link_icon} alt='' h={5} />
                  <Text fontSize={'small'} color={'#2C80FF'} fontWeight={600}>
                    view attachments
                  </Text>
                </Flex>

              </HStack>
            <Box
              position="absolute"
              width="150px"
              height="180px"
              bg="none"
              transform="rotate(45deg)"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bottom={-10}
              right={-7}
              p={0}
            >
              <Text
                // transform="rotate(-45deg)"
                // top="80%"
          // left="50%"
          transform="rotate(-90deg) scale(1.4)"
                color="white"
                fontWeight="bold"
                bg={'orange'}
                w={'100%'}
                fontSize={'10px'}
                textAlign={'center'}
                px={2}
                py={1}
              >
                In Progress
              </Text>
            </Box> 
            </VStack>
           
            {
              showMenu && <>
                <VStack bg='white'
                  rounded={'8'}
                  position={'absolute'}
                  boxShadow='rgba(0, 0, 0, 0.35) 0px 3px 10px'
                  p={2}
                  px={5}
                  py={3}
                  w={'50%'}
                  zIndex={1}
                  gap={1}
                  top="13%"
                  right="10%"
                >
                  <HStack justifyContent={'space-between'}
                    alignItems={'center'}
                    w={'100%'}
                  >

                    <Text w={'100%'}
                      textAlign={'start'}
                      fontSize={'11px'}
                    >
                      edit </Text>
                    <RiEdit2Line size={20} />
                  </HStack>
                  <HStack justifyContent={'space-between'}
                    alignItems={'center'}
                    w='100%'
                  >
                    <Text
                      w={'100%'}
                      textAlign={'start'}
                      fontSize={'11px'}
                    >
                      download </Text>
                    <MdOutlineCloudDownload size={20} />
                  </HStack>
                </VStack>
              </>
            }

          </VStack>
          {isHover ? (
            <Flex
              h={'43px'}
              w={'210px'}
              justifyContent={'center'}
              alignItems={'flex-start'}>
              <Button
                w={'100%'}
                bg={'#2C80FF'}
                h={'100%'}
                borderRadius={'0px 0px 8px 8px'}
                color={'#FFFFFF'}
                fontSize={'sm'}
                _hover={{
                  bg: 'rgba(40, 125, 253, 1)',
                }}
                fontWeight={500}
              //    onClick={handleUnArchive}
              >
                Message
              </Button>
            </Flex>
          ) : (
            ''
          )}
        </VStack>
      ) : (
        <Flex
          minH={'110px'}
          w={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
          p={2}
        >
          <VStack
            minH={'100px'}
            w={'100%'}
            bg={'#FFFFFF'}
            rounded='15'
            p={4}
            justifyContent={'space-between'}
            alignItems={'flex-end'}
            _hover={{
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 3px 10px',
            }}
          >
            <HStack justifyContent={'space-between'} alignItems={'center'} w={'100%'} h={'100%'}>
              <HStack>
                <Checkbox
                // isChecked={selectedArray.some(selectedCard => selectedCard._id === props.item._id)}
                // onClick={() => handleCardClick(props.item)} 
                />
                <Text fontSize={'12px'} fontWeight={600}>
                  {props.item.address},
                </Text>
                <Text fontSize={'12px'}>
                  {props.item.state}, {props.item.city}, {props.item.zipcode}
                </Text>
              </HStack>
              <Flex gap={2}>
                <Image src={link_icon} alt='' h={4} />
                <Text fontSize={'10px'} color={'#2C80FF'}>
                  view attachments
                </Text>
              </Flex>
            </HStack>

            <HStack bg={'#FAFAFA'} rounded={12} w={'98%'} h={'45px'} px={3} py={2} justifyContent={'space-between'}>
              <Flex gap={2}>
                <Image src={property_icon} alt='' h={5} />
                <Text fontSize={'12px'}>Property ID: {props.item.apt_unit}</Text>
              </Flex>
              <Flex gap={2} justifyContent={'flex-start'} alignItems={'center'}>
                <Image src={clock_icon} alt='' h={4} />
                <Text fontSize={'12px'}>
                  <Text>{formattedDate}</Text>
                </Text>
              </Flex>
              <Flex gap={2}>
                <Image src={user_icon} alt='' h={4} />
                <Text fontSize={'12px'}>
                  {props.item.toclient ? `${FirstName} ${LastName}` : 'NA'}
                </Text>
              </Flex>
              <Flex gap={2} justifyContent={'flex-start'} alignItems={'center'}>
                <Image src={property_type_icon} alt='' h={4} />
                <Text fontSize={'12px'}>{props.item.type}</Text>
              </Flex>
              <Image src={dots_icon} alt='' w={'20px'} />
            </HStack>
          </VStack>
        </Flex>
      )}
    </>
  );
};


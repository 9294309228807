import React, { useState } from 'react';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { Box } from '@chakra-ui/react';

const OrdersCalender = () => {
  const [date, setDate] = useState(new Date());

  return (
    <Box>
      <SingleDatepicker
        name="date-input"
        date={date}
        onDateChange={setDate}
        propsConfigs={{
          dayOfMonthBtnProps: {
            todayBtnProps: {
              background: 'teal.400',
              color: 'white',
            },
          },
        }}
      />
    </Box>
  );
};

export default OrdersCalender;




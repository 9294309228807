import { Box, HStack, VStack, Text, Button, Flex,IconButton, InputGroup, InputLeftElement, Input, Image } from '@chakra-ui/react'
// import { Link } from 'react-router-dom'
import React, { useContext } from 'react'
import { useEffect, useState,  useCallback} from 'react'
// import { useForm } from 'react-hook-form';
// import { AiOutlineMore } from 'react-icons/ai';
// import { toast } from 'react-toastify';
import { io } from 'socket.io-client';
import { FiSearch } from "react-icons/fi";
import { MdOutlineAdd } from "react-icons/md";
import menu from '../../assets/ticket-menu.svg'
// import send from '../../assets/ticket-send.svg'
import { IoMdSend } from 'react-icons/io';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import createIcon from '../../assets/create-ticket-icon.svg';
import { url } from '../../config';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import CreateTicket from './CreateTicket';

const ChatContainer = ({ setopenchatcontainer }) => {

const {Token} = useContext(AuthContext)

  const [ticketsarray, setticketsarray] = useState([])
  dayjs.extend(relativeTime);
  const [viewCreateTicket, setviewCreateTicket] = useState(false)
  // const [tikketinfoopen, setticketinfoopen] = useState(false)
  // const [markresolved, setmarkresolved] = useState(false)
  // const [ticketdialogopen, setticketdialogopen] = useState(false)
  // const [isOpen, setIsOpen] = useState(false);
  const [leftchat, setleftchat] = useState([])
  const [byiddata, setbyiddata] = useState({})
  const [ticketid, setticketid] = useState("")
  // const [obj, setobj] = useState({ Subject: "", ticketTitle: "", ticketdesc: "" })
  const [rightchat, setrightchat] = useState([])
  const [message, setmessage] = useState("")
  // const toggleDropdown = () => {
  //     setIsOpen(!isOpen);
  // };
  // const {
  //     register,
  //     handleSubmit,
  //     setError,
  //     setValue,
  //     reset,
  //     getValues,
  //     clearErrors,
  //     formState: { errors },
  // } = useForm();

const getticketById = async (ticketid)=>{
  try {
    const res = await axios.get(`${url}/admin/tickets/details${ticketid}`)
    .then(()=>{
      console.log(res.data)
    })
  } catch (error) {
    console.log(error)
  }
  }

  const fetchTickets = useCallback(async () => {
    try {
        const res = await axios.get(`${url}/user/ticket`, {
            headers: {
                Authorization: `Bearer ${Token}`
            }
        });
        if (res) {
            setticketsarray(res.data.data.map((itm) => ({ ...itm, checked: false })));
        }
    } catch (err) {
        console.log(err);
    }
}, []);

  const socketIo = io('https://dev-ndd-newapi.flynautstaging.com/', {
      transports: ['websocket'], // Try forcing WebSocket
      reconnectionAttempts: 5, // Retry connecting up to 5 times
  });

  useEffect(() => {
      const handleConnect = () => {
          fetchTickets();
          console.log('Connected to backend:', socketIo.id);
      };

      const handleDisconnect = () => {
          console.log('Disconnected from backend');
      };

      const handleConnectError = (err) => {
          console.log('Connection Error:', err);
      };

      socketIo.on('connect', handleConnect);
      socketIo.on('disconnect', handleDisconnect);
      socketIo.on('connect_error', handleConnectError);

      return () => {
          socketIo.off('connect', handleConnect);
          socketIo.off('disconnect', handleDisconnect);
          socketIo.off('connect_error', handleConnectError);
      };
  }, []);

  useEffect(() => {
      if (socketIo && ticketid) {
          socketIo.emit("join_room", ticketid);

          const handleReceiveMessage = (message) => {
              console.log(message, "Received message");
              if (message.sender === "Admin") {
                  setleftchat((prevChat) => [...prevChat, message]);
              }
              console.log(message, "message");
          };

          socketIo.on("receive_message", handleReceiveMessage);

          if (rightchat.length === 0) {
              getticketById(ticketid).then((res) => {
                  if (res) {
                      setleftchat(res.data.data[0].messages.filter((itm) => itm.sender === "Admin"))
                      setrightchat(res.data.data[0].messages.filter((itm) => itm.sender === "Inspector"));
                  }
              });
          }

          return () => {
              socketIo.off("receive_message", handleReceiveMessage);
          };
      }
  }, [socketIo, ticketid, rightchat.length]);

  return (
    <Flex
    w='100%'
    py={3}
    my={2}
    px={20}
    justifyContent={'center'}
    alignItems={'center'}
>
    <Box
      h='100%'
      w={'100%'}
      display={'flex'}
      justifyContent={'space-evenly'}
      alignItems={'center'}
      border={'1px solid #FFFFFF'}
      boxShadow='15px 15px 60px rgba(0, 0, 0, 0.1)'
      borderRadius="24px"
      bg={'rgba(242, 242, 250, 0.7)'}
      py={'10'}
      flexDirection={'column'}
      gap={6}
    >
      <HStack
        w={'85%'}
        justifyContent={'space-between'}
      >
        <VStack>
          <Text fontSize={'lg'}
            fontWeight={700}
            w={'100%'}
            textAlign={'left'}
          >
            Support Chat
          </Text>
          <Text
            fontSize={'12px'}
            color={'rgba(0, 0, 0, 0.5)'}
            w={'100%'}
            textAlign={'left'}
          >Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Text>
        </VStack>
        <Button
          color='#2C80FF'
          fontWeight={600}
          border='none'
          bg='none'
          _hover={
            {
              bg: 'none',
              border: 'none'
            }
          }
          onClick={() => setopenchatcontainer(false)}
        >
          Back</Button>
      </HStack>
      <Flex
      minH={'70vh'}
      mt={2}
      minW={'65vw'}
      justifyContent={'space-between'}
      alignItems={'center'}
      border={'1px solid #FFFFFF'}
      boxShadow='15px 15px 60px rgba(0, 0, 0, 0.1)'
      rounded={20}
      bg={'rgba(242, 242, 250, 0.7)'}
      p={3}
      >
      <VStack w={'35%'}
      h={'100%'}
      justifyContent={'flex-start'}
      alignItems={'flex-start'}
      p={2}
      px={2}
      borderRight={'3px solid rgba(141, 141, 141, 0.3)'}
      >
      <Flex
      justifyContent={'center'}
      alignItems={'center'}
      bg='white'
      w='100%'
      gap={2}
      h={'7vh'}
      rounded='15'
      >
      <InputGroup
      >
      <InputLeftElement
      children={<FiSearch color='grey' />}
      />
      <Input
      type='text'
      placeholder='search'
      color='grey'
      fontSize='13px'
      bg={'none'}
      border='none'
      _focusVisible={
        {bg:'none',
      border:'none'
      }
      }
      />
      </InputGroup>  
      </Flex>
      
      <Button
      color='white'
      
      bg={'#2C80FF'}
      _hover={
        {bg:'#2C80FF',
      border:'none'
      }
      }
      border='none'
      rounded={8}
      justifyContent={'center'}
      gap={1}
      w={'100%'}
      h={'5vh'}
      onClick={() => setviewCreateTicket(true)}
      >
        <Image src={createIcon} alt=''/>
      <Text fontSize={'11px'}
      fontWeight={500}
      >Create New Ticket</Text>
      </Button>
     <VStack w={'100%'}
     h={'50vh'}
     overflowY={'auto'}
     pr={2}
     >
      {ticketsarray.length !== 0 ?
       (ticketsarray.map((itm,index)=>{
        return <HStack key={index} w={'100%'}
        minH={'10vh'}
        _hover={
          {bg:'white',
           cursor:'pointer',
           borderBottom:'none',
           rounded:10
        }
        }
        py={2}
        my={1}
        px={2}
        onClick={() => {
          const copydata = [...ticketsarray]
          setticketid(itm._id)
          setbyiddata({ ...itm })
          setticketsarray(copydata.map((itms, ind) => ({ ...itms, checked: index == ind ? true : false })))
          setleftchat([])
          setrightchat([])
        }}
        justifyContent={'space-between'}
        alignItems={'center'}
        borderBottom={'1px solid rgba(141, 141, 141, 0.3)'}
        >
        <VStack
        justifyContent={'center'}
        alignItems={'center'}
        gap={1}
        w={'100%'}
        h={'100%'}
        >
        <Text fontSize={'13px'}
        w={'100%'}
        textAlign={'start'}
        fontWeight={600}
        >{itm.ticketTitle}</Text>
        <Text fontSize={'10px'} color={'grey'}
        w={'100%'}
        textAlign={'start'}
        >{itm.ticketdesc}</Text>
        </VStack>
        <Text fontSize={'10px'} color={'grey'}
        w={'100%'}
        textAlign={'end'}
        >{dayjs(itm.createdAt).fromNow()}</Text>
        </HStack>
       })):
     <Box
     h={'100%'}
     w={'100%'}
     alignItems={'center'}
     display={'flex'}
     justifyContent={'center'}
     fontSize={'sm'}
     >
      No Tickets 
     </Box>
     }
     </VStack>
      </VStack>
      <VStack w={'65%'}
      h={'62vh'}
      justifyContent={'space-between'}
      p={2}
      px={4}
      >
          <HStack justifyContent={'space-between'}
        w={'100%'}
        >
<Text w={'100%'} textAlign='start'
       fontWeight={600}
       pl={3}
       fontSize={'lg'}
       >{byiddata.ticketTitle}</Text>
       <Image src={menu} alt='' h={'20px'} w={'20px'}/>
        </HStack>
<Box h='80%' w='full'
display={'flex'}
justifyContent={'flex-end'}
alignItems={'flex-end'}
 overflowY='auto' py={2}>
      <VStack w='full' spacing={2}>
        {(ticketid ? [...rightchat, ...leftchat] : [])
          .sort((a, b) => {
            const timeA = new Date(a?.timestamp).getTime();
            const timeB = new Date(b?.timestamp).getTime();

            if (timeA === timeB) {
              return a.sender === "Admin" ? 1 : -1;
            }

            return timeA - timeB;
          })
          .reduce((acc, itm, index, array) => {
            const currentDate = dayjs(itm.timestamp).format("YYYY-MM-DD");
            const prevDate = index > 0 ? dayjs(array[index - 1].timestamp).format("YYYY-MM-DD") : null;

            const isToday = dayjs(itm.timestamp).isSame(new Date(), "day");
            const isYesterday = dayjs(itm.timestamp).isSame(dayjs().subtract(1, "day"), "day");

            if (currentDate !== prevDate) {
              acc.push(
                <Text key={`date-${currentDate}`} textAlign='center' color='gray.400' fontSize='12px' my={4}>
                  {isToday ? "Today" : isYesterday ? "Yesterday" : dayjs(itm.timestamp).format("DD MMMM YYYY")}
                </Text>
              );
            }

            acc.push(
              <HStack
                key={index}
                w='full'
                px={2}
                justifyContent={itm.sender === "Admin" ? 'flex-start' : 'flex-end'}
                mb={2}
                minH={'6vh'}
              >
                {itm.sender === "Admin" ? (
                  <HStack justifyContent={'space-between'}>
                    <Box bg='white' maxW='40%' color='black' p={2} px={4}
                    borderRadius={'14px 14px 14px 0px'}
                    minH={'5vh'}
                    >
                      {itm.message}
                    </Box>
                    <Text color='gray.400' ml={2} alignSelf='flex-end' fontSize={'13px'}>
                      {dayjs(itm.timestamp).format("HH:mm")}
                    </Text>
                  </HStack>
                ) : (
                  <HStack justifyContent={'space-between'}>
                    <Text color='gray.400' mr={2} alignSelf='flex-end'
                    fontSize={'13px'}
                    >
                      {dayjs(itm.timestamp).format("HH:mm")}
                    </Text>
                    <Box bg='#2593D6' maxW='70%' color='white' borderRadius={'14px 14px 0px 14px'} p={2} px={4}
                    fontSize={'sm'}
                    
                    >
                      {itm.message}
                    </Box>
                  </HStack>
                )}
              </HStack>
            );

            return acc;
          }, [])}
      </VStack>
    </Box>
        
        <Box h='10%' w='full'>
      <Flex bg='#2593D6' rounded='full' h='full' w='full' px={3}
      gap={1} justify='space-between' align='center'>
        <Flex bg='white' rounded='full' w='5%' h='65%' justify='center' align='center'>
          <MdOutlineAdd fontSize='20px' />
        </Flex>
        <Flex w='94%' h={'85%'} bg='#FBFBFB' rounded='full' p={2} px={3} 
        py='1'
        align='center'>
          <Input
            type='text'
            placeholder='Type your message...'
            variant='unstyled'
            value={message}
            onChange={(e) => {
              if (ticketid) {
                setmessage(e.target.value);
              }
            }}
            fontSize={'12px'}
          />
          <IconButton
            icon={<IoMdSend color='white' />}
            bg='#00476A'
            rounded='full'
            p={2}
            size={20}
            cursor='pointer'
            onClick={() => {
              if (ticketid) {
                socketIo.emit('send_message', {
                  ticketId: ticketid,
                  sender: 'Inspector',
                  timestamp: new Date(),
                  message: message,
                });
                setrightchat([
                  ...rightchat,
                  {
                    ticketId: ticketid,
                    sender: 'Inspector',
                    timestamp: new Date(),
                    message: message,
                  },
                ]);
                setmessage('');
              }
            }}
          />
        </Flex>
      </Flex>
    </Box>
      </VStack>
      </Flex>
    </Box>
    {
      viewCreateTicket && <CreateTicket setviewCreateTicket={setviewCreateTicket} fetchTickets = {fetchTickets}/>
    }
</Flex>
  )
}

export default ChatContainer
import { Box, Container, VStack, Image, HStack, Text, Input, Button, InputGroup, InputLeftElement } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import background from '../../assets/signup-background.png'
import { signup_page_data } from './signup_page_data'
import left_img from '../../assets/auth-left.png'
import logo from '../../assets/logo.png'
import { Link } from 'react-router-dom'
import google_icon from '../../assets/google-icon.png'
import { toast } from 'react-toastify';
import axios from 'axios';
import user_icon from '../../assets/user_icon_signup.png'
import password_icon from '../../assets/password-icon.png'
import email_icon from '../../assets/email-icon.png'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import { url } from '../../config'
const Signup = () => {
  const { setVerifyemail } = useContext(AuthContext)
  const [Inputs, setInputs] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmpassword: "",
    role: "Client"
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...Inputs, [name]: value })
  }
  const navigate = useNavigate();

  const Register = async (e) => {
    e.preventDefault();
    if (!Inputs.email || !Inputs.password || !Inputs.firstName || !Inputs.lastName || !Inputs.confirmpassword) {
      toast.error("Fields cannot be empty");
      return;
    }
    if (Inputs.password !== Inputs.confirmpassword) {
      toast.error("Confirm password didn't match the password");
      return;
    }

    try {
      const response = await axios.post(`${url}/auth/signup`, Inputs);
      if (response.data.status) {
        toast.success(response.data.message);
        setVerifyemail(Inputs.email)
        setInputs({ firstName: "", lastName: "", password: "", confirmpassword: "" });
        navigate("/verification"); 
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.error}`);
        try {
          const res = await axios.post(`${url}/auth/sendOtp`, Inputs);
          if (res.data.status) {
            toast.success(`Otp sent to ${Inputs.email}`)
          }
          setVerifyemail(Inputs.email)
        } catch (error) {
          console.error(error)
        }
        navigate("/verification")
      } else if (error.request) {
        toast.error("No response from server. Please try again later.");
      } else {
        toast.error(`Error: ${error.message}`);
      }
    }
  };
  return (
    <Container h={'100vh'} maxW={'100vw'} p={0} backgroundColor={'#F8FAFB'} justifyContent={'center'} alignItems={'center'}>
      <VStack h={'100%'} w={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Image w={'100%'} h={'55%'} src={background} alt='' />
        <Box w={'100%'} h={'40%'}
          display={'flex'} justifyContent={'center'} alignItems={'center'}
          flexDirection={'column'}
        >
          <VStack h={'90%'} w={'30%'} justifyContent={'space-evenly'} paddingX={2} paddingY={1} alignItems={'center'}>
            {
              signup_page_data.map((item, index) => {
                return <HStack key={index} h={'42%'} w={'100%'}
                  justifyContent={'center'} alignItems={'center'} gap={4}>
                  <Box h={'40%'} w={'9%'} backgroundColor={'#2C80FF'}
                    borderRadius={'6px'} display={'flex'} justifyContent={'center'} alignItems={'center'} alignSelf={'flex-start'} marginTop={'6px'}>
                    <Image src={item.icon} alt='' h={4} w={4} />
                  </Box>
                  <VStack h={'100%'} w={'58%'} justifyContent={'center'} alignItems={'flex-start'}>
                    <Text fontSize={'12px'} fontWeight={600}>{item.head}</Text>
                    <Text fontSize={'9px'}>{item.content}</Text>
                  </VStack>
                </HStack>
              })
            }

          </VStack>
        </Box>
        <HStack h={'7%'} w={'100%'} justifyContent={'space-between'} alignItems={'center'} px={'100px'}>
          <Text w={'75%'} fontSize={'x-small'} color={'#656A72'}>Nextday Desktop Valuations. all rights reserved</Text>
          <Text w={'10%'} fontSize={'x-small'} color={'#656A72'}>Terms of Service</Text>
          <Text w={'10%'} fontSize={'x-small'} color={'#656A72'}>Privacy Policy</Text>
        </HStack>
      </VStack>
      <Image src={left_img} alt='' position={'absolute'}
        top={'275px'} left={'110px'} h={'290px'} w={'470px'} />\
      <Image src={logo} alt='logo' position={'absolute'}
        top={'30px'} left={'90px'} h={'40px'} w={'160px'} />
      <Box h={'430px'} w={'400px'} position={'absolute'}
        top={'200px'} right={'80px'} backgroundColor={'#FFFFFF'}
        borderRadius={'20px'}
        boxShadow={'0px 3px 6px #00000029'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <VStack h={'90%'} w={'85%'}>
          <Text w={'100%'}
            color={'#191A1C'} fontWeight={600}
            h={'15%'}
            fontSize={'xx-large'} textAlign={'left'}
          >Sign up</Text>
          <Text w={'100%'} color={'#191A1C'} fontWeight={600}
            textAlign={'left'}
            fontSize={'x-small'}
            h={'5%'}
            mb={1}
          >Hi, Lets get familiar</Text>

          <HStack w={'100%'} h={'11%'} justifyContent={'space-between'} alignItems={'center'}>
            <InputGroup h={'100%'}
              w={'49%'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <InputLeftElement
                pointerEvents='none'
                children={<Image src={user_icon} alt='password icon' mb={1} />}
              />
              <Input type='text' placeholder='First name'
                h={'100%'}
                w={'100%'}
                name='firstName'
                value={Inputs.firstName}
                onChange={handleChange}
                borderRadius={'5px'}
                fontSize={'11px'}
                _focusVisible={
                  {
                    border: 'none'
                  }
                }
                backgroundColor={'#F2F2F2'} border={'none'} outline={'none'} />
            </InputGroup>
            <InputGroup h={'100%'}
              w={'49%'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <InputLeftElement
                pointerEvents='none'
                children={<Image src={user_icon} alt='password icon' mb={1} />}
              />
              <Input type='text' placeholder='Last name'
                h={'100%'}
                w={'100%'}
                name='lastName'
                value={Inputs.lastName}
                onChange={handleChange}
                borderRadius={'5px'}
                fontSize={'11px'}
                _focusVisible={
                  {
                    border: 'none'
                  }
                }
                backgroundColor={'#F2F2F2'} border={'none'} outline={'none'} />
            </InputGroup>
          </HStack>
          <InputGroup h={'11%'}
            w={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <InputLeftElement
              pointerEvents='none'
              children={<Image src={email_icon} alt='email icon' mb={1} />}
            />
            <Input type='email' placeholder='Your email'
              h={'100%'}
              w={'100%'}
              borderRadius={'5px'}
              name='email'
              value={Inputs.email}
              onChange={handleChange}
              fontSize={'11px'}
              _focusVisible={
                {
                  border: 'none'
                }
              }
              backgroundColor={'#F2F2F2'} border={'none'} outline={'none'}
            />
          </InputGroup>
          <InputGroup h={'11%'}
            w={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <InputLeftElement
              pointerEvents='none'
              children={<Image src={password_icon} alt='password icon' mb={1} />}
            />
            <Input type='password' placeholder='Password'
              h={'100%'}
              w={'100%'}
              borderRadius={'5px'}
              fontSize={'11px'}
              name='password'
              _focusVisible={
                {
                  border: 'none'
                }
              }
              value={Inputs.password}
              onChange={handleChange}
              backgroundColor={'#F2F2F2'} border={'none'} outline={'none'} />
          </InputGroup>
          <InputGroup h={'11%'}
            w={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <InputLeftElement
              pointerEvents='none'
              children={<Image src={password_icon} alt='password icon' mb={1} />}
            />
            <Input type='password' placeholder='Confirm Password'
              h={'100%'}
              w={'100%'}
              name='confirmpassword'
              value={Inputs.confirmpassword}
              onChange={handleChange}
              borderRadius={'5px'}
              fontSize={'11px'}
              _focusVisible={
                {
                  border: 'none'
                }
              }
              backgroundColor={'#F2F2F2'} border={'none'} outline={'none'} />
          </InputGroup>
          <Button
            borderRadius={'5px'}
            color={'#FFFFFF'}
            bgColor={'#2C80FF'}
            w={'100%'}
            fontSize={'13px'}
            onClick={Register}
            h={'12%'}
          >
            {/* <Link to={'/verification'}> */}
            Register
            {/* </Link>   */}
          </Button>
          <Text w={'100%'}
            fontSize={'13px'}
            textAlign={'center'}
            fontWeight={600}
          >
            or
          </Text>
          <Button
            borderRadius={'5px'}
            w={'100%'}
            border={'1px solid #D9D9D9'}
            gap={2}
            backgroundColor={'#FFFFFF'}
            marginBottom={2}
            h={'12%'}
          >
            <Image src={google_icon} alt='' h={'16px'} w={'16px'} />
            <Text fontSize={'12px'} color={'#1E2021'}>Continue with Google</Text>
          </Button>
          <HStack w={'100%'}
            gap={2}
          >
            <Text color={'#656A72'} fontSize={'11px'}
              textAlign={'left'}>I have an account? </Text>
            <Link to={'/'}>
              <Text fontSize={'11px'} fontWeight={600}
                color={'#2B80FF'}>Login</Text>
            </Link>
          </HStack>
        </VStack>

      </Box>
    </Container>
  )
}

export default Signup
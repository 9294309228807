import React, { useEffect, useContext, useState } from 'react';
import background from '../../assets/dashboard-background.png';
import { VStack, Image, Text, Button, HStack, Box, Flex, Checkbox } from '@chakra-ui/react';
import { AuthContext } from '../../context/AuthContext';
import AddProperty from './AddProperty';
import MyProperties from './myproperties/MyProperties';
import MyOrders from './myorders/MyOrders';
import CounterOffer from './counteroffer/CounterOffer';
import ArchieveList from './archivelist/ArchieveList';
import archiveList from '../../assets/archieve-list-icon.png';
import download_icon from '../../assets/download_blue-icon.png';
import archive_icon_bottom from '../../assets/archive-icon.png';
import archiveListActiveIcon from '../../assets/archieve-list-active-icon.png';
import counteroffer_icon from '../../assets/counter-offer-icon.svg'
import myproperties_icon from '../../assets/my-properties-icon.png'
import myproperties_active_icon from '../../assets/my-properties-active-icon.png'
import myorders_icon from '../../assets/my-orders-icon.png';
import myorders_active_icon from '../../assets/my-orders-active-icon.png'
import active_counter_offer_icon from '../../assets/active-counter-offer-icon.png'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const Dashboard = () => {
  const {
    viewAddProperty,
    setviewAddProperty,
    setPropertiesArray,
    PropertiesArray,
    setArchiveArray,
    Token,
    setOrdersArray,
    fetchProperties,
    ArchieveProperties,
    fetchOrders,
    handleArchive,
    selectedArray,
    ArchiveArray
  } = useContext(AuthContext);
  const [selected, setselected] = useState('My Properties')
  useEffect(() => {
    if (Token) {
      fetchProperties();
    }
  }, [Token, setPropertiesArray]);

  useEffect(() => {
    if (Token) {
      ArchieveProperties();
    }
  }, [Token, setArchiveArray]);

  useEffect(() => {
    if (Token) {
      fetchOrders();
    }
  }, [Token, setOrdersArray]);

  const dashboardCategories = [
    {
      category: 'My Properties',
      activeIcon: myproperties_active_icon,
      icon: myproperties_icon,
      component: <MyProperties selected={selected} />
    },
    {
      category: 'My Orders',
      activeIcon: myorders_active_icon,
      icon: myorders_icon,
      component: <MyOrders selected={selected} />
    },
    {
      category: 'Archieve List',
      activeIcon: archiveListActiveIcon,
      icon: archiveList,
      component: <ArchieveList selected={selected} />
    },
    {
      category: 'Counter Offer',
      activeIcon: active_counter_offer_icon,
      icon: counteroffer_icon,
      component: <CounterOffer selected={selected} />
    },
  ]

  const [filterComponents, setfilterComponents] = useState(dashboardCategories[0])

  useEffect(() => {
    setfilterComponents(dashboardCategories.find((d) => d.category === selected))
  }, [selected])

  const navigate = useNavigate();

  return (
    <>
      {PropertiesArray.length !== 0 || ArchiveArray.length !==0 ? (<>
        <VStack
          h={'85vh'}
          w={'100%'}
          py={3}
          px={20} justifyContent={'flex-start'} alignItems={'flex-start'} gap={0}>
          <HStack w={'100%'}
            pl={20}
            justifyContent={'flex-start'} gap={3} alignItems={'flex-end'}>
            {
              dashboardCategories.map((d, index) => {
                return <Button key={index}
                  h={'100%'}
                  maxH={['4vh', '4vh', '4vh', '4vh', '8vh', '8vh']}
                  py={5}
                  px={8}
                  bg={selected === d.category ? 'white' : '#F2F2F2'}
                  fontSize={'sm'}
                  borderRadius={'20px 20px 0px 0px'}
                  onClick={() => setselected(d.category)}
                  gap={2}
                  _hover={
                    {
                      bg: selected === d.category ? 'white' : '#F2F2F2'
                    }
                  }
                >
                  {
                    selected === d.category ?
                      <Image src={d.activeIcon} alt='' />
                      :
                      <Image src={d.icon} alt='' />
                  }
                  <Text fontSize={['10px', '10px', '10px', '10px', '14px']}
                    color={selected === d.category ? '#2593D6' : '#000000'}
                  >{d.category}</Text>
                </Button>
              })
            }
          </HStack>
          {filterComponents?.component && (
            <Box
              h={'90%'}
              w={'100%'}
              display={'flex'}
              justifyContent="center"
              alignItems={'center'}
              border={'1px solid #FFFFFF'}
              boxShadow='15px 15px 60px rgba(0, 0, 0, 0.1)'
              borderRadius="24px"
              bg={'rgba(242, 242, 250, 0.7)'}
            >
              {filterComponents.component}
            </Box>
          )}
        </VStack>
        {
          selected === 'My Properties' &&
          <Flex
            minH={'7vh'}
            position={'absolute'}
            bottom={['80px', '80px', '80px', '80px', '80px', '100px']}
            right={'60px'}
            rounded={12}
            bg={'#2C80FF'}
            justifyContent={'space-between'}
            alignItems={'center'}
            px={7}
            gap={4}
            boxShadow={'0px 20px 20px #0000000B'}
          >
            <Checkbox h={[4, 4, 4, 4, 4, 6]} w={[4, 4, 4, 4, 4, 6]} />
            <Text fontSize={'11px'} color={'#FFFFFF'}>All Property Selected</Text>
            <Button
              bg={'#FFFFFF'}
              _hover={
                { bg: '#FFFFFF' }
              }
              h={'35px'}
              w={'120px'}
              fontSize={'11px'}
              color={'#2C80FF'}
              rounded={8}
              onClick={()=>{
                selectedArray.length !==0 ? navigate('order-appraisal') :
                toast.error("Select properties for Order appraisal")
              }}
            >
                Order Appraisal
            </Button>
            <HStack>
              <Image src={download_icon} alt='' />
              <Text fontSize={'11px'} color={'#FFFFFF'}>Download</Text>
            </HStack>
            <Button
            bg='none'
            border='none'
            color='white'
              _hover={{ cursor: 'pointer',
            bg:'none',
            border:'none',
            color:'white'
            }}
              onClick={handleArchive}
              gap={2}
            >
              <Image src={archive_icon_bottom} alt='' />
              <Text fontSize={'11px'} color={'#FFFFFF'}
              >Archieve</Text>
            </Button>
          </Flex>
        }
      </>
      ) : (
        <VStack
          w={'38%'} justifyContent={'space-between'} alignItems={'center'} gap={2}>
          <Image src={background} alt=""
          />
          <Text
            w={'100%'}
            color={'#191A1C'}
            fontSize={'27px'}
            fontWeight={600}
            textAlign={'center'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            Hi Kumar, Welcome to <br />
            <Text as={'span'} fontWeight={400} color="#2593D6">
              Next Day Desktop Valuations
            </Text>
          </Text>
          <Text
            my={5}
            color={'#191A1C'} textAlign={'center'} fontSize={'13px'}
          >
            There are no New Appraisals ordered yet.
            <br />
            If you want to create new appraisals you can find the options below.
          </Text>
          <Button
            h={'37px'}
            w={'400px'}
            bgColor={'#2C80FF'}
            color={'#FFFFFF'}
            fontSize={'sm'}
            rounded={8}
            _hover={{
              backgroundColor: 'rgba(40, 120, 245, 1)',
            }}
            onClick={() => setviewAddProperty(true)}
          >
            Add New Property
          </Button>
        </VStack>
      )}

      {viewAddProperty && <AddProperty />}
    </>
  );
};

export default Dashboard;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { AuthProvider } from './context/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const theme = extendTheme({
  colors: {
    "primary": {
      "50": "#fef5f4",
      "100": "#fbd9d1",
      "200": "#f8b6a7",
      "300": "#f48871",
      "400": "#f16b4e",
      "500": "#ec360e",
      "600": "#cb2905",
      "700": "#a42104",
      "800": "#8b1c04",
      "900": "#661403"
    }
  },
  styles: {
    global: {
      'html, body': {
        bg: 'primary.100',
      },
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </AuthProvider>
    <ToastContainer/>
  </React.StrictMode>
);
reportWebVitals();

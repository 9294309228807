import React, { useContext } from 'react'
import { HStack, Flex, Text, Button, Box, Image, Input, VStack } from '@chakra-ui/react';
import close_btn from '../../assets/close-btn.png'
import layout_icon from '../../assets/layout-icon.png';
import active_layout_icon from '../../assets/active-layout-icon.png';
import active_grid_icon from '../../assets/active-grid-icon.png';
import grid_icon from '../../assets/grid-icon.png';
import sort_icon from '../../assets/sort-icon.png';
import filter_icon from '../../assets/filter-icon.png';
import { AuthContext } from '../../context/AuthContext';
export default function DashboardNavbar({ selected, propertyTypeFilter, setPropertyTypeFilter, setStateFilter, stateFilter }) {

    const { gridView, setgridView } = useContext(AuthContext);
    const handlePropertyTypeChange = (e) => {
        setPropertyTypeFilter(e.target.value);
    };

    const handleStateChange = (e) => {
        setStateFilter(e.target.value);
    };

    const clearPropertyTypeFilter = () => {
        setPropertyTypeFilter('');
    };

    const clearStateFilter = () => {
        setStateFilter('');
    };
    return (
        <VStack
            w={'100%'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            gap={1}
        >
            <Text
                color={'rgba(0, 0, 0, 0.5)'}
                fontSize={'small'}
                w={'100%'}
                textAlign={'left'}
                pl={3}
                my={2}
            >
                Access all NextDay products in one place
            </Text>
            <HStack
                h={'7vh'}
                w='100%' justifyContent={ selected === 'My Properties' ? 'space-between': 'flex-end'} alignItems={'center'}>
                {
                    selected === 'My Properties' &&
                    <Flex gap={4}
                        h={'100%'}
                        justifyContent={'flex-start'} alignItems={'center'}>
                        <HStack
                            gap={2}
                        >
                            <Text fontSize={'small'}
                                color={'#000000'} textAlign={'center'}>
                                Property type
                            </Text>
                            <Box position="relative"
                                w={'120px'}
                                h={'28px'}>
                                <Input
                                    type='text'
                                    h='100%'
                                    w='100%'
                                    bg={'#EAEAEA'}
                                    borderRadius={'xl'}
                                    _focusVisible={'none'}
                                    fontSize={'sm'}
                                    value={propertyTypeFilter}
                                    onChange={handlePropertyTypeChange}
                                />
                                {propertyTypeFilter && (
                                    <Image src={close_btn}
                                        position="absolute"
                                        right={1}
                                        top={1}
                                        cursor="pointer"
                                        h={6}
                                        onClick={clearPropertyTypeFilter}
                                        alt='' />
                                )}
                            </Box>
                        </HStack>
                        <HStack
                            gap={2}
                        >
                            <Text fontSize={'small'} color={'#000000'} textAlign={'center'}>
                                State
                            </Text>
                            <Box position="relative"
                                w={'120px'}
                                h={'28px'}>
                                <Input
                                    type='text'
                                    h='100%'
                                    w='100%'
                                    bg={'#EAEAEA'}
                                    borderRadius={'xl'}
                                    _focusVisible={'none'}
                                    fontSize={'sm'}
                                    value={stateFilter}
                                    onChange={handleStateChange}
                                />
                                {stateFilter && (
                                    <Image src={close_btn}
                                        position="absolute"
                                        right={1}
                                        top={1}
                                        cursor="pointer"
                                        h={6}
                                        onClick={clearStateFilter}
                                        alt='' />
                                )}
                            </Box>
                        </HStack>
                        <Text fontSize={'13px'}
                            color={'#2C80FF'}
                            onClick={() => { setPropertyTypeFilter(''); setStateFilter(''); }}
                            _hover={{ cursor: 'pointer' }}
                        >Clear filters</Text>
                    </Flex>
                }
                <HStack gap={5}>
                 <HStack
                    bg={'#F5F5F5'}
                    // w={'10%'}
                >
                    <Button
                        bg={gridView ? 'none' : '#2C80FF'}
                        rounded={6}
                        p={1}
                        onClick={() => setgridView(false)}
                        _hover={
                            { bg: gridView ? 'none' : '#2C80FF' }
                        }
                    >
                        {
                            gridView ? <Image src={layout_icon} alt='' color={'grey'}
                                w={'20px'}
                                h={'20px'} /> : <Image src={active_layout_icon} alt=''
                                    w={'20px'}
                                    h={'20px'}
                            />
                        }

                    </Button>
                    <Button
                        bg={gridView ? '#2C80FF' : 'none'}
                        rounded={6}
                        p={1}
                        onClick={() => setgridView(true)}
                        _hover={
                            { bg: gridView ? '#2C80FF' : 'none' }
                        }
                    >
                        {
                            gridView ? <Image src={active_grid_icon} alt='' color={'grey'}
                                w={'20px'}
                                h={'20px'} /> : <Image src={grid_icon} alt='' color={'grey'}
                                    w={'20px'}
                                    h={'20px'} />
                        }
                    </Button>
                </HStack>
                <Flex gap={4}
                    justifyContent={'flex-end'} alignItems={'center'}
                    py={1}
                    px={1}
                >
                    <Button
                        bg={'#2C80FF'}
                        color={'#FFFFFF'}
                        fontSize={'small'}
                        _hover={
                            {
                                bg: '#2C80FF'
                            }
                        }
                        rounded={10}
                        w={'100px'}
                        gap={2}
                    >
                        <Image src={sort_icon} alt='' />
                        Sort</Button>
                    <Button
                        bg={'#2C80FF'}
                        color={'#FFFFFF'}
                        fontSize={'small'}
                        _hover={
                            {
                                bg: '#2C80FF'
                            }
                        }
                        gap={2}
                        rounded={10}
                        w={'100px'}
                    >
                        <Image src={filter_icon} alt='' />
                        Filter</Button>
                </Flex>   
                </HStack>
                
            </HStack>
        </VStack>
    )
}
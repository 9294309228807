import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import search_icon from '../../assets/search-icon.png';
import message_icon from '../../assets/message-icon.png';
import menu_icon from '../../assets/menu-icon.png';
import dashboard_icon from '../../assets/dashboard-icon.png';
import dashboard_icon_active from '../../assets/dashboard-icon-active.png';
import calender_icon from '../../assets/calender-icon.png';
import calender_icon_active from '../../assets/calender-icon-active.png';
import reports_icon from '../../assets/reports-icon.png';
import reports_icon_active from '../../assets/reports-icon-active.png';
import { MdOutlineNotificationsActive } from "react-icons/md";
import { Box, Image, HStack, Button, Text, Flex } from '@chakra-ui/react';
import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
const Navbar = () => {
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState(location.pathname);

  const handleLinkClick = (path) => {
    setActiveRoute(path);
  };

  const getLinkStyle = (path) => ({
    color: activeRoute === path ? '#2593D6' : '#656A72'
  });

  const getImageSrc = (path, defaultIcon, activeIcon) => (
    activeRoute === path ? activeIcon : defaultIcon
  );

  const { setviewAddProperty, viewProfilePopup, setviewProfilePopup, FirstName, LastName } = useContext(AuthContext);
  const UserName = `${FirstName.charAt(0)}${LastName.charAt(0)}`;
  return (
    <HStack
      w={'100%'}
      px={'10'}
      justifyContent={'space-between'} alignItems={'center'}
      my={3}
    >
      <Box
        display={'flex'}
        justifyContent={'flex-start'}
        alignItems={'center'}
        w={'100%'}>
        <Image src={logo} alt='' w={'130px'} />
      </Box>
      <Flex
        w={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <HStack
          backgroundColor={'#FFFFFF'}
          boxShadow={'0px 10px 20px #0000000B'}
          minH={['50px','50px','50px','50px','50px','55px']}
          rounded={[10,10,10,10,10,12]}
          px={[10,10,10,10,10,14]}
          justifyContent={'space-evenly'}
          gap={[7,7,7,7,7,10]}
          alignItems={'center'}>
          <Link to={'/'} onClick={() => handleLinkClick('/')}>
            <HStack h={'100%'} w={'100%'} position="relative"
              justifyContent={'center'}
              alignItems={'center'}
              gap={1}
            >
              <Image src={getImageSrc('/', dashboard_icon, dashboard_icon_active)} alt='' h={5} w={5} />
              <Text fontSize={'sm'} style={getLinkStyle('/')}
              >Dashboard</Text>
              {activeRoute === '/' && (
                <Box
                  position="absolute"
                  borderRadius={'3px'}
                  mt={1}
                  bottom={0} left="25%" w="40%" h="2px" bg="#2593D6" />
              )}
            </HStack>
          </Link>
          <Link to={'/calender'} onClick={() => handleLinkClick('/calender')}>
            <HStack h={'100%'} w={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={1}
              position="relative">
              <Image src={getImageSrc('/calender', calender_icon, calender_icon_active)} alt='' h={5} w={5} />
              <Text fontSize={'sm'} style={getLinkStyle('/calender')}>Calender</Text>
              {activeRoute === '/calender' && (
                <Box position="absolute" bottom={0} left="25%" w="40%"
                  borderRadius={'3px'}
                  mt={1}
                  h="2px" bg="#2593D6" />
              )}
            </HStack>
          </Link>
          <Link to={'/reports'} onClick={() => handleLinkClick('/reports')}>
            <HStack h={'100%'} w={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={1}
              position="relative">
              <Image src={getImageSrc('/reports', reports_icon, reports_icon_active)} alt=''
                h={5} w={5} />
              <Text fontSize={'sm'} style={getLinkStyle('/reports')}>Reports</Text>
              {activeRoute === '/reports' && (
                <Box position="absolute"
                  mt={1} borderRadius={'3px'} bottom={0} left="25%" w="40%" h="2px" bg="#2593D6" />
              )}
            </HStack>
          </Link>
        </HStack>
      </Flex>
      <Flex
        w={'100%'}
        justifyContent={'flex-end'}
        alignItems={'center'}
      >
        <HStack
          minH={'47px'}
          pl={20}
          gap={'4'}
          alignItems={'center'}>
          <Button
            h={['43px','43px','43px','43px','43px','50px']}
            backgroundColor={'#2C80FF'}
            rounded={10}
            color={'#FFFFFF'}
            fontSize={'13px'}
            w={['170px','170px','170px','170px','175px','220px']}
            _hover={
              {
                backgroundColor: 'rgba(40, 120, 245, 1)'
              }
            }
            onClick={() => setviewAddProperty(true)}
          >
            Add new property
          </Button>
          <Image src={search_icon} alt='' height={'20px'} w={'20px'} />
          <Image src={message_icon} alt='' height={'20px'} w={'20px'} />
          <MdOutlineNotificationsActive size={20} color='#656A72' />
          <HStack justifyContent={'center'}
          maxW={'100px'}
            gap={1}>
            <Box 
            h={'40px'}
            w={'43px'} 
            display={'flex'}
              alignItems={'center'}
              color={'#2C80FF'}
              fontWeight={600}
              m={1}
              justifyContent={'center'}
              rounded={'full'}
              boxShadow={'0px 3px 6px rgba(0, 0, 0, 0.16)'}
              onClick={() => setviewProfilePopup(!viewProfilePopup)}
              _hover={
                { cursor: 'pointer' }
              }
            >
              {UserName}
            </Box>
            <Image src={menu_icon} alt='' onClick={() => setviewProfilePopup(!viewProfilePopup)}
              _hover={
                { cursor: 'pointer' }
              }
            />
          </HStack>
        </HStack>
      </Flex>
    </HStack>
  );
}

export default Navbar;

import { Box, Container, VStack, Image, HStack, Text, Input, Button } from '@chakra-ui/react'
import React from 'react'
import background from '../../assets/signup-background.png'
import { login_page_data } from './login_page_data'
import left_img from '../../assets/auth-left.png'
import logo from '../../assets/logo.png'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { url } from '../../config'
const Verification = () => {
    const { Verifyemail } = useContext(AuthContext);
    // const [Inputs, setInputs] = useState({
    //     email:Verifyemail,
    //     otp: "",
    //     role: "Client"
    // })
    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setInputs({ ...Inputs, [name]: value })
    // }
    const [otp, setOtp] = useState(["", "", "", ""]);
    const navigate = useNavigate();

    const handleChange = (e, index) => {
        const { value } = e.target;
        if (/^[0-9]$/.test(value) || value === "") {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
        }
    }


    const handleKeyUp = (e, index) => {
        if (e.key === "Backspace" && otp[index] === "" && index > 0) {
            document.getElementById(`otp-${index - 1}`).focus();
        } else if (/^[0-9]$/.test(e.key) && index < 3) {
            document.getElementById(`otp-${index + 1}`).focus();
        }
    }

    const Verify = async (e) => {
        e.preventDefault();
        const combinedOtp = otp.join("");
        if (combinedOtp.length !== 4) {
            toast.error("OTP must be 4 digits");
            return;
        }
        try {
            const response = await axios.post(`${url}/auth/verifyOtp`, { email: Verifyemail, otp: combinedOtp, role: "Client" });
            if (response.data.status) {
                toast.success(response.data.message);
                setOtp(["", "", "", ""]);
                navigate("/login");
            } else {
                toast.error(response.data.error);
            }
        } catch (error) {
            if (error.response) {
                toast.error(`Error: ${error.response.data.message}`);
            } else if (error.request) {
                toast.error("No response from server. Please try again later.");
            } else {
                toast.error(`Error: ${error.message}`);
            }
        }
    }

    const Resend = async () => {
        try {
            const res = await axios.post(`${url}/auth/sendOtp`, {
                email: Verifyemail,
                role: "Client"
            });
            if (res.data.status) {
                toast.success(`Resent Otp to ${Verifyemail}`)
                navigate('/verification')
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Container h={'100vh'} maxW={'100vw'} p={0} backgroundColor={'#F8FAFB'} justifyContent={'center'} alignItems={'center'}>
            <VStack h={'100%'} w={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                <Image w={'100%'} h={'55%'} src={background} alt='' />
                <Box w={'100%'} h={'40%'}
                    display={'flex'} justifyContent={'center'} alignItems={'center'}
                    flexDirection={'column'}
                >
                    <VStack h={'90%'} w={'30%'} justifyContent={'space-evenly'} paddingX={4} paddingY={3} alignItems={'center'}>
                        {
                            login_page_data.map((item, index) => {
                                return <HStack key={index} h={'42%'} w={'100%'}
                                    justifyContent={'center'} alignItems={'center'} gap={4}>
                                    <Box h={'34%'} w={'9%'} backgroundColor={'#2C80FF'}
                                        borderRadius={'md'} display={'flex'} justifyContent={'center'} alignItems={'center'} alignSelf={'flex-start'} marginTop={'6px'}>
                                        <Image src={item.icon} alt='' h={4} w={4} />
                                    </Box>
                                    <VStack h={'100%'} w={'58%'} justifyContent={'center'} alignItems={'flex-start'}>
                                        <Text fontSize={'sm'} fontWeight={600}>{item.head}</Text>
                                        <Text fontSize={'10px'}>{item.content}</Text>
                                    </VStack>
                                </HStack>
                            })
                        }

                    </VStack>
                </Box>
                <HStack h={'4vh'} w={'100%'} justifyContent={'space-between'} alignItems={'center'} px={'100px'}>
                    <Text w={'75%'} fontSize={'x-small'} color={'#656A72'}>Nextday Desktop Valuations. all rights reserved</Text>
                    <Text w={'10%'} fontSize={'x-small'} color={'#656A72'}>Terms of Service</Text>
                    <Text w={'10%'} fontSize={'x-small'} color={'#656A72'}>Privacy Policy</Text>
                </HStack>
            </VStack>
            <Image src={left_img} alt='' position={'absolute'}
                top={'275px'} left={'110px'} h={'290px'} w={'470px'} />\
            <Image src={logo} alt='logo' position={'absolute'}
                top={'30px'} left={'90px'} h={'40px'} w={'160px'} />
            <Box h={'250px'} w={'400px'} position={'absolute'}
                top={'220px'} right={'80px'} backgroundColor={'#FFFFFF'}
                borderRadius={'20px'}
                boxShadow={'0px 3px 6px #00000029'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <VStack h={'85%'} w={'83%'}>
                    <Text w={'100%'} color={'#191A1C'} fontWeight={600}
                        fontSize={'30px'} textAlign={'left'}
                    >Verification</Text>
                    <Text w={'100%'} color={'#191A1C'} fontWeight={600}
                        textAlign={'left'}
                        fontSize={'11px'}
                    >We have sent verification code to your email.</Text>
                    <HStack justifyContent={'space-evenly'}
                        w={'100%'}
                    >
                        {otp.map((digit, index) => (
                            <Input
                                key={index}
                                id={`otp-${index}`}
                                type='text'
                                maxLength={1}
                                value={digit}
                                onChange={(e) => handleChange(e, index)}
                                onKeyUp={(e) => handleKeyUp(e, index)}
                                h={'38px'}
                                w={'24%'}
                                textAlign={'center'}
                                borderRadius={'5px'}
                                fontSize={'12px'}
                                backgroundColor={'#F2F2F2'}
                                border={'none'}
                                outline={'none'}
                                _focusVisible={
                                    { border: 'none' }
                                }
                            />
                        ))}
                    </HStack>
                    <Button
                        borderRadius={'5px'}
                        color={'#FFFFFF'}
                        bgColor={'#2C80FF'}
                        w={'100%'}
                        fontSize={'13px'}
                        onClick={Verify}
                    >
                        Submit
                    </Button>
                    <HStack w={'100%'}
                        gap={2}
                    >
                        <Text color={'#656A72'} fontSize={'11px'}
                            textAlign={'left'}>Didn’t receive the code?</Text>
                        <Link to={'/'}>
                            <Text fontSize={'11px'} fontWeight={600}
                                color={'#2B80FF'}
                                onClick={Resend}
                            >Resend</Text>
                        </Link>
                    </HStack>
                </VStack>
            </Box>
        </Container>
    )
}

export default Verification
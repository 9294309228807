import React, { useState } from 'react'
import Card from '../../common/Card'
import add_icon from '../../../assets/add_property_second_icon.png'
import { Container, Flex, HStack, Text, VStack, Image, Box } from '@chakra-ui/react'
import { AuthContext } from '../../../context/AuthContext';
import { useContext } from 'react';
import { FaArrowRight } from "react-icons/fa6"; 
import DashboardNavbar from '../../common/DashboardNavbar';
const MyProperties = ({ selected }) => {
  const { setviewAddProperty, PropertiesArray, gridView } = useContext(AuthContext);
  const [propertyTypeFilter, setPropertyTypeFilter] = useState('');
  const [stateFilter, setStateFilter] = useState('')

  const filteredProperties = PropertiesArray.filter(property => {
    const matchesPropertyType = property.Propertytype.toLowerCase().includes(propertyTypeFilter.toLowerCase());
    const matchesState = property.state.toLowerCase().includes(stateFilter.toLowerCase());
    return (!propertyTypeFilter || matchesPropertyType) && (!stateFilter || matchesState);
  });

  return (
    <Container h={'90%'} w={'93%'} maxW={'93%'} overflowY={'auto'}>
      <VStack h={'100%'} w={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        {
          PropertiesArray.length !==0 ?
         <>    
        <DashboardNavbar selected={selected}
          propertyTypeFilter={propertyTypeFilter}
          setPropertyTypeFilter={setPropertyTypeFilter}
          setStateFilter={setStateFilter}
          stateFilter={stateFilter}
        />
          {gridView ?
            <Flex
              h={'80%'}
              w={'100%'}
              flexWrap={'wrap'}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              overflowY={'auto'}
            >
              {
                filteredProperties.length > 0 ? (
                  <>
                    <VStack
                      h={'272px'}
                      w={'270px'}
                      my={2}
                      mr={2}
                      bg={'#FFFFFF'}
                      justifyContent={'space-between'}
                      alignContent={'center'}
                      rounded={14}
                      p={5}
                      onClick={() => setviewAddProperty(true)}
                      _hover={{ cursor: 'pointer' }}
                    >
                      <Image src={add_icon} alt='' h={'50%'} />
                      <Text
                        fontSize={'small'}
                        color={'#71706F'}
                        h={'20%'}
                        w={'80%'}
                        textAlign={'center'}
                      >
                        Lorem Ipsum is simply dummy text of the printing
                      </Text>
                      <Text
                        fontSize={'sm'}
                        color={'#2C80FF'}
                        h={'20%'}
                        w={'80%'}
                        textAlign={'center'}
                        fontWeight={600}
                      >
                        Add New Property
                      </Text>
                    </VStack>
                    {filteredProperties.map((item, index) => {
                      return <Card key={index} item={item} index={index} gridView={gridView} />
                    })}
                  </>) : (
                  <Box fontSize={'lg'}
                    h={'30vh'} color={'#71706F'} w={'100%'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >No matches found</Box>
                )
              }
            </Flex>
            :
            <HStack
              h={'85%'}
              w={'100%'}
              gap={2}
              p={2}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
            >
              <VStack
                w={'60%'}
                gap={0}
                h={'100%'}
              >
                {
                  filteredProperties.length > 0 ? (
                    <>
                      <HStack
                        h={'110px'}
                        w={'100%'}
                        bg={'#FFFFFF'}
                        rounded='15'
                        justifyContent={'space-between'}
                        alignContent={'center'}
                        p={3}
                        m={2}
                        onClick={() => setviewAddProperty(true)}
                        _hover={{ cursor: 'pointer' }}
                      >
                        <Box
                          bg={'#FAFAFA'}
                          rounded={8}
                          display='flex'
                          justifyContent={'center'}
                          alignItems={'center'}
                          h={'98%'}
                          w={'80px'}
                          p={1}
                        >
                          <Image src={add_icon} alt='' h={'80%'} />
                        </Box>
                        <VStack
                          gap={2}
                          w={'100%'}
                          pl={4}
                        >
                          <Text
                            fontSize={'sm'}
                            color={'#2C80FF'}
                            w={'100%'}
                            textAlign={'start'}
                            fontWeight={600}
                          >
                            Add New Property
                          </Text>
                          <Text
                            fontSize={'small'}
                            color={'#71706F'}
                            w={'100%'}
                            textAlign={'start'}
                          >
                            Lorem Ipsum is simply dummy text of the printing
                          </Text>
                        </VStack>
                        <Box
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          h={'30px'}
                          w={'35px'}
                          p={2}
                          borderRadius='50%'
                          bg='#2C80FF'
                        >
                          <FaArrowRight color='white' />
                        </Box>
                      </HStack>
                      <VStack h={'50vh'}
                        overflowY={'auto'}
                        w={'100%'}
                        gap={1}
                        p={0}
                      >
                        {
                          filteredProperties.map((item, index) => {
                            return <Card key={index} item={item} index={index} gridView={gridView} />
                          })
                        }
                      </VStack>
                    </>) : <Box fontSize={'lg'}
                      h={'30vh'} color={'#71706F'} w={'100%'}
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >No matches found</Box>
                }
              </VStack>
              <Box
                w={'35%'}
                h={'90%'}
                rounded={16}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243207.75285017327!2d83.09777638978257!3d17.73893044894249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a39431389e6973f%3A0x92d9c20395498468!2sVisakhapatnam%2C%20Andhra%20Pradesh!5e0!3m2!1sen!2sin!4v1727375988869!5m2!1sen!2sin"
                  width="100%" height="100%"
                  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='map'></iframe>
              </Box>
            </HStack>
          }
        </>
        : <Box
          h={'100%'} 
          w={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          >
           All Properties are archieved !
          </Box>
        }
      </VStack>
    </Container>
  )
}

export default MyProperties
import { Box, Container, VStack, Image, HStack, Text, Input, Checkbox, Button, InputGroup, InputLeftElement } from '@chakra-ui/react'
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import background from '../../assets/login-background.png'
import { login_page_data } from './login_page_data'
import left_img from '../../assets/auth-left.png'
import logo from '../../assets/logo.png'
import { Link } from 'react-router-dom'
import google_icon from '../../assets/google-icon.png'
import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import password_icon from '../../assets/password-icon.png'
import email_icon from '../../assets/email-icon.png'
import { url } from '../../config';

const Login = () => {
   const {setUser,setuserId, setToken, setuserMail, setFirstName, setLastName} = useContext(AuthContext)
  const [Inputs, setInputs] = useState({
    email: "",
    password: "",
    role:"Client"
  })
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...Inputs, [name]: value })
  }
  const navigate = useNavigate();

  const Submit = async (e) => {
    e.preventDefault();
    if (!Inputs.email || !Inputs.password) {
      toast.error("Fields cannot be empty");
      return;
    }
    try {
      const response = await axios.post(`${url}/auth/login`, Inputs);
      if (response.data.status) {
        toast.success(response.data.message);
        setInputs({ email: "", password: ""});
        const id = response.data.data._id
        setuserId(id)
        localStorage.setItem("userId", id);

        const token = response.data.data.token;
        setToken(token);
        localStorage.setItem("token", token);

        const firstname = response.data.data.firstName
        setFirstName(firstname);
        localStorage.setItem("firstName", firstname);

        const lastname = response.data.data.lastName
        setLastName(lastname);
        localStorage.setItem("lastName", lastname);

        const usermail = response.data.data.email
        setuserMail(usermail);
        localStorage.setItem("userMail", usermail);
        
        setUser(true);
        navigate("/");
        console.log(response.data);
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.error}`);
      } else if (error.request) {
        toast.error("No response from server. Please try again later.");
      } else {
        toast.error(`Error: ${error.message}`);
      }
    }
  };

  return (
    <Container h={'100vh'} maxW={'100vw'} p={0} backgroundColor={'#F8FAFB'} justifyContent={'center'} alignItems={'center'}>
      <VStack h={'100%'} w={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Image w={'100%'} h={'55%'} src={background} alt='' />
        <Box w={'100%'} h={'40%'}
          display={'flex'} justifyContent={'center'} alignItems={'center'}
          flexDirection={'column'}
        >
          <VStack h={'90%'} w={'30%'} justifyContent={'space-evenly'} paddingX={4} paddingY={3} alignItems={'center'}>
            {
              login_page_data.map((item, index) => {
                return <HStack key={index} h={'42%'} w={'100%'}
                  justifyContent={'center'} alignItems={'center'} gap={4}>
                  <Box h={'34%'} w={'9%'} backgroundColor={'#2C80FF'}
                    borderRadius={'md'} display={'flex'} justifyContent={'center'} alignItems={'center'} alignSelf={'flex-start'} marginTop={'6px'}>
                    <Image src={item.icon} alt='' h={4} w={4} />
                  </Box>
                  <VStack h={'100%'} w={'58%'} justifyContent={'center'} alignItems={'flex-start'}>
                    <Text fontSize={'sm'} fontWeight={600}>{item.head}</Text>
                    <Text fontSize={'10px'}>{item.content}</Text>
                  </VStack>
                </HStack>
              })
            }

          </VStack>
        </Box>
        <HStack h={'7%'} w={'100%'} justifyContent={'space-between'} alignItems={'center'} px={'100px'}>
          <Text w={'75%'} fontSize={'x-small'} color={'#656A72'}>Nextday Desktop Valuations. all rights reserved</Text>
          <Text w={'10%'} fontSize={'x-small'} color={'#656A72'}>Terms of Service</Text>
          <Text w={'10%'} fontSize={'x-small'} color={'#656A72'}>Privacy Policy</Text>
        </HStack>
      </VStack>
      <Image src={left_img} alt='' position={'absolute'}
        top={'275px'} left={'110px'} h={'290px'} w={'470px'} />\
      <Image src={logo} alt='logo' position={'absolute'}
        top={'30px'} left={'90px'} h={'40px'} w={'160px'} />
      <Box h={'390px'} w={'385px'} position={'absolute'}
        top={'230px'} right={'90px'} backgroundColor={'#FFFFFF'}
        borderRadius={'20px'}
        boxShadow={'0px 3px 6px #00000029'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <VStack h={'85%'} w={'85%'}>
          <Text w={'100%'} h={'18%'} color={'#191A1C'} fontWeight={600}
            fontSize={'xx-large'} textAlign={'left'}
          >Sign in</Text>
          <Text w={'100%'} h={'5%'} color={'#191A1C'} fontWeight={600}
            textAlign={'left'}
            fontSize={'x-small'}
          >Sign in with your email</Text>
          <InputGroup h={'15%'} 
          w={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
          >
            <InputLeftElement
              pointerEvents='none'
              children={<Image src={email_icon} alt='email icon' mt={1} />}
            />
            <Input type='email' placeholder='Your email'
              h={'100%'}
              w={'99%'}
              borderRadius={'md'}
              fontSize={'11px'}
              name='email'
              value={Inputs.email}
              onChange={handleChange}
              _focusVisible={
                {
                  border:'none'
                }
              }
              backgroundColor={'#F2F2F2'} border={'none'} outline={'none'}
              marginTop={1} />
          </InputGroup>
          <InputGroup h={'15%'} 
          w={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
          >
            <InputLeftElement
              pointerEvents='none'
              children={<Image src={password_icon} alt='password icon' mt={1} />}
            />
          <Input type='password' placeholder='Password'
            h={'100%'}
            w={'99%'}
            borderRadius={'md'}
            fontSize={'11px'}
            name='password'
            value={Inputs.password}
            onChange={handleChange}
            _focusVisible={
              {
                border:'none'
              }
            }
            backgroundColor={'#F2F2F2'} border={'none'} outline={'none'} />
            </InputGroup>
          <HStack w={'100%'} h={'5%'} justifyContent={'space-between'} alignItems={'center'}>
            <Checkbox size={'sm'} />
            <Text fontSize={'10px'} w={'20%'}>Remember</Text>
            <Text w={'75%'} fontSize={'10px'} color={'#2B80FF'} textAlign={'right'}>
              <Link to={'/forget'}>Forgot your password?
              </Link> </Text>
          </HStack>
          <Button
            borderRadius={'md'}
            color={'#FFFFFF'}
            bgColor={'#2C80FF'}
            w={'100%'}
            fontSize={'sm'}
            onClick={Submit}
            h={'15%'}
          >
            Login
          </Button>
          <Text w={'100%'}
            fontSize={'small'}
            textAlign={'center'}
            fontWeight={600}
            h={'5%'}
          >
            or
          </Text>
          <Button
            borderRadius={'md'}
            w={'100%'}
            border={'1px solid #D9D9D9'}
            gap={2}
            backgroundColor={'#FFFFFF'}
            h={'15%'}
          >
            <Image src={google_icon} alt='' h={'15px'} w={'15px'} />
            <Text fontSize={'small'} color={'#1E2021'}>Continue with Google</Text>
          </Button>
          <HStack w={'100%'}
            gap={2}
            h={'4%'}
          >
            <Text color={'#656A72'} fontSize={'11px'}
              textAlign={'left'}>Don’t have an account? </Text>
            <Link to={'/signup'}>
              <Text fontSize={'11px'} fontWeight={600}
                color={'#2B80FF'}>Signup</Text>
            </Link>
          </HStack>
        </VStack>

      </Box>
    </Container>
  )
}

export default Login

import book from '../../assets/education-book.png';
import cube from '../../assets/education-cube.png'

export const login_page_data = [
    {
        icon:book,
        head:'Lorem Ipsum is simply dummy text of the printing',
        content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
        icon:cube,
        head:'Lorem Ipsum is simply dummy text of the printing',
        content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    }
]
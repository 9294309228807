import { Flex, VStack, Box, Select, Image, HStack, Text, Button, FormLabel, Input, Textarea } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { useState } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import axios from 'axios'
import NewOrderPopup from '../NewOrderPopup'
import { FaPlus } from "react-icons/fa6";
import { toast } from 'react-toastify'
import upload_icon from '../../../assets/upload-icon.png'
import { url } from '../../../config'
import close_red_icon from '../../../assets/close-btn-red.png'
const PropertyForm = () => {

    const [toClient, settoClient] = useState(false)
    const { Token, FirstName, LastName, setviewaddOrder, handleCardClick, viewaddOrder } = useContext(AuthContext)
    const [formData, setFormData] = useState({
        images: [],
        toclient: false,
        Propertytype: '',
        address: '',
        aptunit: '',
        city: '',
        state: '',
        zipcode: '',
        additionalinfo: ''
    });

    const handleToClientChange = (value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            toclient: value
        }));
        settoClient(!toClient);
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        if (files.length) {
            setFormData((prev) => ({
                ...prev,
                images: [...prev.images, ...files]
            }));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleRemoveImage = (index) => {
        setFormData((prev) => ({
            ...prev,
            images: prev.images.filter((_, i) => i !== index)
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData();
        formData.images.map((image) => {
            return data.append(`images`, image);
        });
        data.append('toclient', formData.toclient);
        data.append('Propertytype', formData.Propertytype);
        data.append('additionalinfo', formData.additionalinfo);
        data.append('zipcode', formData.zipcode);
        data.append('state', formData.state);
        data.append('city', formData.city);
        data.append('aptunit', formData.aptunit);
        data.append('address', formData.address);

        if (formData.images.length === 0) {
            toast.error("Atleast Add One Image ")
            return;
        }

        if (!formData.Propertytype || !formData.additionalinfo || !formData.address || !formData.aptunit || !formData.city || !formData.state || !formData.zipcode) {
            toast.error("Fields cannot be empty");
            return;
        }
        try {
            const response = await axios.post(`${url}/client/property`, data, {
                headers: {
                    'Authorization': `Bearer ${Token}`
                }
            });
            if (response.status === 200) {
                toast.success('Property inserted successfully');
                console.log(response.data);
                handleCardClick(response.data.data);
                setviewaddOrder(true);
                setFormData({
                    images: [],
                    toclient: false,
                    Propertytype: '',
                    address: '',
                    aptunit: '',
                    city: '',
                    state: '',
                    zipcode: '',
                    additionalinfo: ''
                });
            } else {
                console.error('Error inserting property:', response.statusText);
            }
        } catch (error) {
            console.error('Network error:', error);
        }
    };

    return (
        <>
            <Flex
                w='100%'
                py={3}
                my={2}
                px={20}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Flex
                    w={'100%'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    gap={0}
                    p={10}
                    border={'1px solid #FFFFFF'}
                    boxShadow='15px 15px 60px rgba(0, 0, 0, 0.1)'
                    borderRadius="24px"
                    bg={'rgba(242, 242, 250, 0.7)'}
                >
                    <VStack
                        justifyContent={'flex-start'}
                        alignItems={'flex-start'}
                        w={'50%'}
                        pl={['0', '0', '0', '0', '2', '10']}
                        gap={1}
                    >
                        <Text fontSize={'xl'}
                            fontWeight={600}
                            w='100%'
                            textAlign={'start'}
                        >
                            Add New Property
                        </Text>
                        <HStack
                            w={'100%'}
                            justifyContent={'flex-start'} alignItems={'center'}
                            gap={4}
                        >
                            <Text
                                fontSize={'small'}
                                fontWeight={500}
                                color={'rgb(0,0,0,0.5)'}
                            >Are you adding this property to this client?</Text>
                            <HStack gap={3}>
                                <Button
                                    borderRadius='50%'
                                    h={'55px'}
                                    w={'55px'}
                                    color={toClient ? '#FFFFFF' : '#656A72'}
                                    bg={toClient ? '#2C80FF' : '#FFFFFF'}
                                    onClick={() => handleToClientChange(true)}
                                    fontSize={'sm'}
                                    _hover={
                                        {
                                            bg: toClient ? '#2C80FF' : '#FFFFFF',
                                            color: toClient ? '#FFFFFF' : '#656A72'
                                        }
                                    }
                                >
                                    Yes
                                </Button>
                                <Button
                                    borderRadius='50%'
                                    h={'55px'}
                                    w={'55px'}
                                    fontSize={'sm'}
                                    color={!toClient ? '#FFFFFF' : '#656A72'}
                                    bg={!toClient ? '#2C80FF' : '#FFFFFF'}
                                    onClick={() => handleToClientChange(false)}
                                    _hover={
                                        {
                                            bg: !toClient ? '#2C80FF' : '#FFFFFF',
                                            color: !toClient ? '#FFFFFF' : '#656A72'
                                        }
                                    }
                                >
                                    No
                                </Button>
                            </HStack>

                            <Input type='hidden' name='toclient' value={formData.toclient} />

                        </HStack>
                        <VStack
                            minH={'60vh'}
                            w={'100%'}
                            justifyContent={'space-evenly'}
                            align={'flex-start'}
                            gap={3}
                            py={3}
                        >
                            {
                                formData.toclient && <VStack
                                    gap={3}
                                    w={'100%'}
                                >
                                    <HStack
                                        w={'100%'}
                                        justifyContent={'space-between'}
                                    >
                                        <VStack
                                            w={'50%'}
                                            justifyContent={'center'}
                                            alignItems={'flex-start'}
                                            gap={1}
                                        >
                                            <FormLabel
                                                fontSize={'11px'}
                                                fontWeight={600}
                                                color={'#656A72'}
                                                w={'100%'}
                                                textAlign={'start'}
                                            >
                                                First Name
                                            </FormLabel>
                                            <Input type='text' value={FirstName}
                                                bg={'#F2F2F2'}
                                                fontSize={'11px'}
                                                color='black'
                                                rounded={10}
                                                h={'45px'}
                                                border={'1px solid #CCCCCC'}
                                                _focusVisible={
                                                    {
                                                        border: '1px solid #CCCCCC',
                                                        bg: '#F2F2F2'
                                                    }
                                                }
                                            />
                                        </VStack>
                                        <VStack
                                            w={'45%'}
                                            justifyContent={'center'}
                                            alignItems={'flex-start'}
                                            gap={1}
                                        >
                                            <FormLabel
                                                fontSize={'11px'}
                                                fontWeight={600}
                                                color={'#656A72'}
                                                w={'100%'}
                                                textAlign={'start'}
                                            >
                                                Last Name
                                            </FormLabel>
                                            <Input type='text'
                                                value={LastName}
                                                bg={'#F2F2F2'}
                                                fontSize={'11px'}
                                                color='black'
                                                h={'45px'}
                                                rounded={10}
                                                border={'1px solid #CCCCCC'}
                                                _focusVisible={
                                                    {
                                                        border: '1px solid #CCCCCC',
                                                        bg: '#F2F2F2'
                                                    }
                                                }
                                            />
                                        </VStack>
                                    </HStack>
                                    <HStack
                                        w="100%"
                                    >
                                        <FaPlus size={12} color='#2C80FF' />
                                        <Text fontSize={'11px'}
                                            w={'100%'}
                                            textAlign={'start'}
                                            color={'#2C80FF'}
                                            fontWeight={600}
                                        >
                                            Add Owner
                                        </Text>
                                    </HStack>

                                </VStack>
                            }

                            <VStack
                                w={'100%'}
                                justifyContent={'center'}
                                alignItems={'flex-start'}
                                gap={1}
                            >
                                <FormLabel fontSize={'11px'}
                                    fontWeight={600}
                                    color={'#656A72'}
                                    w={'100%'}
                                    textAlign={'start'}
                                >
                                    Property Type
                                </FormLabel>
                                <Select
                                    placeholder='Select property type'
                                    bg={'#F2F2F2'}
                                    w={'50%'}
                                    fontSize={'11px'}
                                    h={'45px'}
                                    name='Propertytype'
                                    onChange={handleChange}
                                    value={formData.Propertytype}
                                    border={'1px solid #CCCCCC'}
                                    _focusVisible={{
                                        border: '1px solid #CCCCCC',
                                        bg: '#F2F2F2'
                                    }}
                                    color='black'
                                    rounded={10}
                                >
                                    <option value='Apartment'>Apartment</option>
                                    <option value='Mobile Home'>Mobile Home</option>
                                    <option value='Bungalow'>Bungalow</option>
                                </Select>
                            </VStack>
                            <Flex
                                w={'100%'}
                                justifyContent={'space-between'}
                            >
                                <VStack
                                    w={'50%'}
                                    justifyContent={'center'}
                                    alignItems={'flex-start'}
                                    gap={1}
                                >
                                    <FormLabel fontSize={'11px'}
                                        fontWeight={600}
                                        color={'#656A72'}
                                        w={'100%'}
                                        textAlign={'start'}

                                    >
                                        Subject Property Address *
                                    </FormLabel>
                                    <Input
                                        type='text'
                                        placeholder='Enter your subject property'
                                        bg={'#F2F2F2'}
                                        name='address'
                                        fontSize={'11px'}
                                        h={'45px'}
                                        value={formData.address}
                                        onChange={handleChange}
                                        _focusVisible={
                                            {
                                                border: '1px solid #CCCCCC',
                                                bg: '#F2F2F2'
                                            }
                                        }
                                        rounded={10}
                                        color='black'
                                        border={'1px solid #CCCCCC'}
                                    />
                                </VStack>
                                <VStack
                                    w='45%'
                                    gap={1}
                                    justifyContent={'center'}
                                    alignItems={'flex-start'}>
                                    <FormLabel
                                        fontSize={'11px'}
                                        w={'100%'}
                                        textAlign={'start'}
                                        color={'#656A72'}
                                    >
                                        Apt. / Unit #
                                    </FormLabel>
                                    <Input
                                        type='text'
                                        placeholder='Enter #'
                                        bg={'#F2F2F2'}
                                        name='aptunit'
                                        h={'45px'}
                                        value={formData.aptunit}
                                        onChange={handleChange}
                                        rounded={10}
                                        _focusVisible={
                                            {
                                                border: '1px solid #CCCCCC',
                                                bg: '#F2F2F2'
                                            }
                                        }
                                        fontSize={'11px'}
                                        color='#656A72'
                                        border={'1px solid #CCCCCC'}
                                    />
                                </VStack>
                            </Flex>
                            <Flex
                                w={'100%'}
                                justifyContent={'space-between'}
                            >
                                <VStack
                                    w={'32%'}
                                    justifyContent={'center'}
                                    alignItems={'flex-start'}
                                    gap={1}
                                >
                                    <FormLabel
                                        fontSize={'11px'}
                                        fontWeight={600}
                                        color={'#656A72'}
                                        w={'100%'}
                                        textAlign={'start'}
                                    >
                                        City *
                                    </FormLabel>
                                    <Input
                                        type='text'
                                        placeholder='Enter your City'
                                        bg={'#F2F2F2'}
                                        onChange={handleChange}
                                        name='city'
                                        h={'45px'}
                                        value={formData.city}
                                        fontSize={'11px'}
                                        rounded={10}
                                        _focusVisible={
                                            {
                                                border: '1px solid #CCCCCC',
                                                bg: '#F2F2F2'
                                            }
                                        }
                                        color='#656A72'
                                        border={'1px solid #CCCCCC'}
                                    />
                                </VStack>
                                <VStack
                                    w={'32%'}
                                    justifyContent={'center'}
                                    alignItems={'flex-start'}
                                    gap={1}
                                >
                                    <FormLabel
                                        fontSize={'11px'}
                                        fontWeight={600}
                                        color={'#656A72'}
                                        w={'100%'}
                                        textAlign={'start'}
                                    >
                                        State *
                                    </FormLabel>
                                    <Input
                                        type='text'
                                        placeholder='Enter your state'
                                        bg={'#F2F2F2'}
                                        name='state'
                                        h={'45px'}
                                        fontSize={'11px'}
                                        rounded={10}
                                        onChange={handleChange}
                                        _focusVisible={
                                            {
                                                border: '1px solid #CCCCCC',
                                                bg: '#F2F2F2'
                                            }
                                        }
                                        value={formData.state}
                                        color='#656A72'
                                        border={'1px solid #CCCCCC'}
                                    />
                                </VStack>
                                <VStack
                                    w={'32%'}
                                    justifyContent={'center'}
                                    alignItems={'flex-start'}
                                    gap={1}
                                >
                                    <FormLabel
                                        fontSize={'11px'}
                                        fontWeight={600}
                                        color={'#656A72'}
                                        w={'100%'}
                                        textAlign={'start'}
                                    >
                                        Zip Code *
                                    </FormLabel>
                                    <Input
                                        type='text'
                                        placeholder='Enter Zip Code'
                                        bg={'#F2F2F2'}
                                        h={'45px'}
                                        name='zipcode'
                                        rounded={10}
                                        onChange={handleChange}
                                        value={formData.zipcode}
                                        fontSize={'11px'}
                                        _focusVisible={
                                            {
                                                border: '1px solid #CCCCCC',
                                                bg: '#F2F2F2'
                                            }
                                        }
                                        color='#656A72'
                                        border={'1px solid #CCCCCC'}
                                    />
                                </VStack>
                            </Flex>
                            <VStack
                                w={'100%'}
                                justifyContent={'center'}
                                alignItems={'flex-start'}

                                gap={1}
                            >
                                <FormLabel
                                    fontSize={'11px'}
                                    fontWeight={600}
                                    color={'#656A72'}
                                    w={'100%'}
                                    textAlign={'start'}
                                >
                                    Additional Information
                                </FormLabel>
                                <Textarea
                                    type='text'
                                    placeholder='Additional Information'
                                    bg={'#F2F2F2'}
                                    name='additionalinfo'
                                    value={formData.additionalinfo}
                                    onChange={handleChange}
                                    fontSize={'11px'}
                                    rounded={10}
                                    h={['120px', '120px', '120px', '120px', '120px', '150px']}
                                    _focusVisible={
                                        {
                                            border: '1px solid #CCCCCC',
                                            bg: '#F2F2F2'
                                        }
                                    }
                                    color='black'
                                    border={'1px solid #CCCCCC'}
                                />
                            </VStack>
                            <HStack
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Flex flexDirection={'column'}
                                    justifyContent={'center'}
                                    alignItems={'flex-start'}
                                    gap={2}
                                >
                                    <Text
                                        fontSize={'10px'}
                                        fontWeight={500}
                                        color={'#656A72'}
                                        w={'100%'}
                                        textAlign={'start'}
                                    >Upload Images & Documents
                                    </Text>
                                    <HStack>
                                        <Box
                                            border="2px"
                                            borderStyle="dotted"
                                            borderColor="gray.300"
                                            rounded={12}
                                            p={3}
                                            textAlign="center"
                                            position="relative"
                                            bg='white'
                                            h={'90px'}
                                            w={'100px'}
                                        >
                                            <Input
                                                type="file"
                                                name="images"
                                                onChange={handleImageChange}
                                                position="absolute"
                                                top="0"
                                                left="0"
                                                opacity="0"
                                                w="100%"
                                                h="100%"
                                                cursor="pointer"
                                            />
                                            <Flex
                                                direction="column"
                                                align="center"
                                                justify="center"
                                                h="100%"
                                                pointerEvents="none"
                                            >
                                                <Image src={upload_icon} alt='' />
                                                <Text mt={2} color='#656A72' fontSize={'10px'}>
                                                    Upload Sign
                                                </Text>
                                            </Flex>
                                        </Box>
                                        <Flex gap={2}>
                                            {formData.images.map((image, index) => (
                                                <Box key={index} p={0} rounded={12}
                                                    display={'flex'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    bg={'white'}
                                                    position={'relative'} height={'90px'} width={'100px'}>
                                                    <Image src={URL.createObjectURL(image)} alt={`Selected image ${index + 1}`}
                                                        objectFit="cover"
                                                        h={'70px'}
                                                        w={'80px'}
                                                        rounded={12}
                                                    />
                                                    <Image src={close_red_icon} alt=''
                                                        position="absolute"
                                                        top={0}
                                                        right={0}
                                                        h={10}
                                                        w={10}
                                                        _hover={{ cursor: 'pointer' }}
                                                        onClick={() => handleRemoveImage(index)}
                                                    />
                                                </Box>
                                            ))}
                                        </Flex>
                                    </HStack>
                                </Flex>
                            </HStack>

                        </VStack>
                    </VStack>
                    <VStack
                        justifyContent={'center'}
                        alignItems={'center'}
                        gap={5}
                        w={'45%'}
                    >
                        <Box
                            h={['500px', '500px', '500px', '500px', '530px', '600px']}
                            w={'100%'}
                            display={'flex'}
                            justifyContent={'center'}
                            alignContent={'center'}
                            rounded='20'
                        >
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243207.75285017327!2d83.09777638978257!3d17.73893044894249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a39431389e6973f%3A0x92d9c20395498468!2sVisakhapatnam%2C%20Andhra%20Pradesh!5e0!3m2!1sen!2sin!4v1727375988869!5m2!1sen!2sin"
                                width="100%" height="100%"
                                allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='map'></iframe>
                        </Box>
                        <Flex w={'100%'} justifyContent={'flex-end'} alignItems={'center'}
                            gap={3}
                        >
                            <Button
                                border={'1px solid #2C80FF'}
                                bg={'none'}
                                color={'#2C80FF'}
                                w='180px'
                                h={'45px'}
                                _hover={
                                    {
                                        bg: 'none',
                                        color: '#2C80FF'
                                    }
                                }
                                rounded={12}
                            >
                                <NavLink to={'/dashboard'}>
                                    <Text fontSize={'12px'}>
                                        Back to Dashboard
                                    </Text>

                                </NavLink>
                            </Button>
                            <Button
                                rounded={12}
                                bg={'#2C80FF'}
                                color={'#FFFFFF'}
                                w='180px'
                                h={'45px'}
                                _hover={
                                    {
                                        bg: '#2C80FF',
                                        color: '#FFFFFF'
                                    }
                                }
                                onClick={handleSubmit}
                            >
                                <Text fontSize={'12px'}>
                                    Add to My Properties
                                </Text>

                            </Button>
                        </Flex>
                        <HStack>

                        </HStack>
                    </VStack>
                </Flex>
            </Flex>
            {viewaddOrder && <NewOrderPopup />}
        </>
    )
}

export default PropertyForm
import { Flex, HStack, Box, Text, Radio, RadioGroup, Select, Textarea, VStack, Input, FormLabel, Checkbox, Image, Divider, Button } from '@chakra-ui/react'
import React, { useContext, useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import question_icon from '../../../assets/question-icon.png';
import property_icon from '../../../assets/add-single-property-icon.png';
import { AuthContext } from '../../../context/AuthContext';
import { toast } from 'react-toastify';
import DueDate from './DueDate';
import axios from 'axios';
import { url } from '../../../config';
import { useNavigate } from 'react-router-dom';
import add_icon from '../../../assets/add-icon-home.png';

export default function AddAppraisal() {
  const [selectedCard, setSelectedCard] = useState("");
  const { selectedArray, setViewDuedate, ViewDuedate, setselectedArray, Token } = useContext(AuthContext);
  
  const Ids = selectedArray.map((item) => item._id)

  const [Inputs, setInputs] = useState({
    address: "",
    apt_unit: "",
    city: "",
    state: "",
    zipcode: "",
    reason: "",
    add_info: "",
    type: "",
    add_on: "Rental Grid",
    property_id: Ids
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const productAddon_data = [
    {
      title: "ARV (as repaired value)",
      cost: "$50.00"
    },
    {
      title: "Rental Grid",
      cost: "$75.00"
    },
    {
      title: "Listings",
      cost: "$50.00"
    },
    {
      title: "Adjustments",
      cost: "$65.00"
    },
    {
      title: "Retrospective",
      cost: "$75.00"
    },
    {
      title: "Rush",
      cost: "$50.00"
    }
  ]
  const selectApparisal_data = [
    {
      title: "Desktop",
      des: "Lorem ipsom is simple Dummy Text",
      cost: "$100.00"
    },
    {
      title: "Desktop with Exterior",
      des: "Lorem ipsom is simple Dummy Text",
      cost: "$150.00"
    },
    {
      title: "MIT/Full Interior Inspection",
      des: "Lorem ipsom is simple Dummy Text",
      cost: "$200.00"
    },
    {
      title: "PDR",
      des: "Lorem ipsom is simple Dummy Text",
      cost: "$150.00"
    },
    {
      title: "Desk Review/Appraisal Risk Review",
      des: "Lorem ipsom is simple Dummy Text",
      cost: "$125.00"
    },
    {
      title: "BPO/ARBPO",
      des: "Lorem ipsom is simple Dummy Text",
      cost: "$150.00"
    }
  ]

  //   const [selectedAddOns, setSelectedAddOns] = useState([]);

  // const handleAddOnChange = (addOn) => {
  //     setSelectedAddOns((prev) => {
  //         if (prev.includes(addOn)) {
  //             return prev.filter(item => item !== addOn);
  //         } else {
  //             return [...prev, addOn];
  //         }
  //     });
  // };

  useEffect(() => {
    setInputs((prev) => ({
      ...prev,
      type: selectedCard
    }));
  }, [selectedCard]);

const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!Inputs.address || !Inputs.type || !Inputs.apt_unit || !Inputs.add_info || !Inputs.add_on || !Inputs.reason || !Inputs.state || !Inputs.zipcode || !Inputs.city || !Inputs.property_id) {
      toast.error("Fields Can't be Empty")
    }
    try {
      const res = await axios.post(`${url}/client/orders`, [Inputs], {
        headers: {
          'Authorization': `Bearer ${Token}`
        }
      }
      )
      if (res) {
        toast.success("Order appraisal is added")
        setselectedArray([])
        navigate("/")
        setInputs({
          address: "",
          apt_unit: "",
          city: "",
          state: "",
          zipcode: "",
          reason: "",
          add_info: "",
          type: "",
          add_on: "Rental Grid",
          property_id: Ids
        })
      }
    } catch (error) {
      console.error(error)
    }
    //  setViewDuedate(true)
  }

  return (
    <>
      <Flex
        w='100%'
        py={3}
        my={2}
        px={20}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Flex
          w={'100%'}
          justifyContent={'space-between'} alignItems={'flex-start'} gap={0}
          border={'1px solid #FFFFFF'}
          boxShadow='15px 15px 60px rgba(0, 0, 0, 0.1)'
          borderRadius="24px"
          bg={'rgba(242, 242, 250, 0.7)'}
          px={10}
          py={6}
          flexDirection={'column'}
        >
          <Text
            w={'100%'}
            textAlign={'start'}
            fontWeight={600}
            color={'black'}
            fontSize={'xl'}
            mb={2}
          >
            Order New Appraisal
          </Text>
          <HStack
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            w={'100%'}
          >
            <VStack
              w={'53%'}
              justifyContent={'space-evenly'}
              align={'flex-start'}
              gap={4}
              py={3}
            >
              <HStack
                justifyContent={'space-between'}
                alignItems={'center'}
                w={'100%'}
              >
                <Text
                  fontSize={'sm'}
                >
                  Selected Properties
                </Text>
                <Button
                  h={['43px', '43px', '43px', '43px', '43px', '50px']}
                  backgroundColor={'#2C80FF'}
                  rounded={10}
                  color={'#FFFFFF'}
                  fontSize={'13px'}
                  w={['170px', '170px', '170px', '170px', '175px', '220px']}
                  _hover={
                    {
                      backgroundColor: 'rgba(40, 120, 245, 1)'
                    }
                  }
                  gap={2}
                >
                  <Image src={add_icon} alt='' />
                  Add new property
                </Button>
              </HStack>
              <Flex
                w={'100%'}
                flexWrap={'wrap'}
                gap={3}
                p={2}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
              >
                {
                  selectedArray.map((item, index) => {
                    return <VStack
                      h='100px'
                      w='220px'
                      gap='2'
                      p={4}
                      key={index}
                      bg='white'
                      rounded={15}
                    >
                      <Text fontSize={'12px'} fontWeight={600}
                        w='100%'
                        textAlign='start'
                      >
                        {item.address}
                      </Text>
                      <Text fontSize={'11px'}
                        w='100%'
                        textAlign='start'
                      >
                        {item.state}, {item.city}, {item.zipcode}
                      </Text>
                      <Flex gap={2}
                        w='100%'
                      >
                        <Image src={property_icon} alt='' w={'4'} h='4' />
                        <Text fontSize={'11px'}>Property ID: {item.aptunit}</Text>
                      </Flex>
                    </VStack>
                  })
                }
              </Flex>
              <Text
                w={'100%'}
                textAlign={'start'}
                fontWeight={600}
                color={'black'}
                fontSize={'md'}
              >
                Address
              </Text>
              <Flex
                w={'100%'}
                justifyContent={'space-between'}
              >
                <VStack
                  w={'50%'}
                  justifyContent={'center'}
                  alignItems={'flex-start'}
                  gap={1}
                >
                  <FormLabel fontSize={'11px'}
                    fontWeight={600}
                    color={'#656A72'}
                    w={'100%'}
                    textAlign={'start'}
                  >
                    Subject Property Address *
                  </FormLabel>
                  <Input
                    type='text'
                    placeholder='Enter your subject property'
                    bg={'#F2F2F2'}
                    name='address'
                    fontSize={'11px'}
                    h={'45px'}
                    value={Inputs.address}
                    onChange={handleChange}
                    _focusVisible={
                      {
                        border: '1px solid #CCCCCC',
                        bg: '#F2F2F2'
                      }
                    }
                    rounded={10}
                    color='black'
                    border={'1px solid #CCCCCC'}
                  />
                </VStack>
                <VStack
                  w='45%'
                  gap={1}
                  justifyContent={'center'}
                  alignItems={'flex-start'}>
                  <FormLabel
                    fontSize={'11px'}
                    w={'100%'}
                    textAlign={'start'}
                    color={'#656A72'}
                  >
                    Apt. / Unit #
                  </FormLabel>
                  <Input
                    type='text'
                    placeholder='Enter #'
                    bg={'#F2F2F2'}
                    name='apt_unit'
                    h={'45px'}
                    value={Inputs.apt_unit}
                    onChange={handleChange}
                    rounded={10}
                    _focusVisible={
                      {
                        border: '1px solid #CCCCCC',
                        bg: '#F2F2F2'
                      }
                    }
                    fontSize={'11px'}
                    color='#656A72'
                    border={'1px solid #CCCCCC'}
                  />
                </VStack>
              </Flex>
              <Flex
                w={'100%'}
                justifyContent={'space-between'}
              >
                <VStack
                  w={'32%'}
                  justifyContent={'center'}
                  alignItems={'flex-start'}
                  gap={1}
                >
                  <FormLabel
                    fontSize={'11px'}
                    fontWeight={600}
                    color={'#656A72'}
                    w={'100%'}
                    textAlign={'start'}
                  >
                    City *
                  </FormLabel>
                  <Input
                    type='text'
                    placeholder='Enter your City'
                    bg={'#F2F2F2'}
                    onChange={handleChange}
                    name='city'
                    h={'45px'}
                    value={Inputs.city}
                    fontSize={'11px'}
                    rounded={10}
                    _focusVisible={
                      {
                        border: '1px solid #CCCCCC',
                        bg: '#F2F2F2'
                      }
                    }
                    color='#656A72'
                    border={'1px solid #CCCCCC'}
                  />
                </VStack>
                <VStack
                  w={'32%'}
                  justifyContent={'center'}
                  alignItems={'flex-start'}
                  gap={1}
                >
                  <FormLabel
                    fontSize={'11px'}
                    fontWeight={600}
                    color={'#656A72'}
                    w={'100%'}
                    textAlign={'start'}
                  >
                    State *
                  </FormLabel>
                  <Input
                    type='text'
                    placeholder='Enter your state'
                    bg={'#F2F2F2'}
                    name='state'
                    h={'45px'}
                    fontSize={'11px'}
                    rounded={10}
                    onChange={handleChange}
                    _focusVisible={
                      {
                        border: '1px solid #CCCCCC',
                        bg: '#F2F2F2'
                      }
                    }
                    value={Inputs.state}
                    color='#656A72'
                    border={'1px solid #CCCCCC'}
                  />
                </VStack>
                <VStack
                  w={'32%'}
                  justifyContent={'center'}
                  alignItems={'flex-start'}
                  gap={1}
                >
                  <FormLabel
                    fontSize={'11px'}
                    fontWeight={600}
                    color={'#656A72'}
                    w={'100%'}
                    textAlign={'start'}
                  >
                    Zip Code *
                  </FormLabel>
                  <Input
                    type='text'
                    placeholder='Enter Zip Code'
                    bg={'#F2F2F2'}
                    h={'45px'}
                    name='zipcode'
                    rounded={10}
                    onChange={handleChange}
                    value={Inputs.zipcode}
                    fontSize={'11px'}
                    _focusVisible={
                      {
                        border: '1px solid #CCCCCC',
                        bg: '#F2F2F2'
                      }
                    }
                    color='#656A72'
                    border={'1px solid #CCCCCC'}
                  />
                </VStack>
              </Flex>
              <VStack
                w={'100%'}
                justifyContent={'center'}
                alignItems={'flex-start'}
                gap={1}
              >
                <FormLabel fontSize={'11px'}
                  fontWeight={600}
                  color={'#656A72'}
                  w={'100%'}
                  textAlign={'start'}
                >
                  Reason for Appraisal
                </FormLabel>
                <Select
                  placeholder='Select'
                  bg={'#F2F2F2'}
                  w={'100%'}
                  fontSize={'11px'}
                  h={'45px'}
                  name='reason'
                  onChange={handleChange}
                  value={Inputs.reason}
                  border={'1px solid #CCCCCC'}
                  _focusVisible={{
                    border: '1px solid #CCCCCC',
                    bg: '#F2F2F2'
                  }}
                  color='black'
                  rounded={10}
                >
                  <option value='For Order Approvel'>For Order Approvel</option>
                  <option value='Need to Check again'>Need to Check again</option>
                </Select>
              </VStack>
              <VStack
                w={'100%'}
                justifyContent={'center'}
                alignItems={'flex-start'}
                gap={1}
              >
                <FormLabel
                  fontSize={'11px'}
                  fontWeight={600}
                  color={'#656A72'}
                  w={'100%'}
                  textAlign={'start'}
                >
                  Additional Information
                </FormLabel>
                <Textarea
                  type='text'
                  placeholder='Additional Information'
                  bg={'#F2F2F2'}
                  name='add_info'
                  value={Inputs.add_info}
                  onChange={handleChange}
                  fontSize={'11px'}
                  rounded={10}
                  h={['120px', '120px', '120px', '120px', '120px', '150px']}
                  _focusVisible={
                    {
                      border: '1px solid #CCCCCC',
                      bg: '#F2F2F2'
                    }
                  }
                  color='#656A72'
                  border={'1px solid #CCCCCC'}
                />
              </VStack>
              <Box
                h={['500px', '500px', '500px', '500px', '600px', '670px']}
                w={'100%'}
                display={'flex'}
                justifyContent={'center'}
                alignContent={'center'}
                rounded='20'
              >
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243207.75285017327!2d83.09777638978257!3d17.73893044894249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a39431389e6973f%3A0x92d9c20395498468!2sVisakhapatnam%2C%20Andhra%20Pradesh!5e0!3m2!1sen!2sin!4v1727375988869!5m2!1sen!2sin"
                  width="100%" height="100%"
                  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='map'></iframe>
              </Box>
            </VStack>
            <VStack
              w={'47%'}
              justifyContent={'space-evenly'}
              align={'flex-start'}
              gap={5}
              py={3}
              pl={5}
            >
              <VStack
                w={'100%'}
                gap={4}
                mb={2}
              >
                <Text w={'100%'}
                  textAlign={'start'}
                  fontWeight={600}
                  color={'black'}
                  fontSize={'md'}
                >
                  Select Appraisal Type
                </Text>
                <RadioGroup onChange={setSelectedCard} value={selectedCard}
                  w={'100%'}
                >
                  <VStack
                    w={'100%'}
                    gap={3}
                  >
                    {
                      selectApparisal_data.map((item, index) => {
                        return <HStack
                          key={index}
                          overflow="hidden"
                          p={3}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          bg="white"
                          w={'100%'}
                          rounded={14}
                        >
                          <Radio value={`${item.title}`} />
                          <VStack
                            w={'80%'}
                          >
                            <Text
                              fontSize={'13px'}
                              fontWeight={600}
                              w={'100%'}
                              textAlign={'start'}
                            >{item.title}</Text>
                            <Text
                              fontSize={'11px'}
                              fontWeight={500}
                              color={'grey.300'}
                              w={'100%'}
                              textAlign={'start'}
                            >
                              {item.des}
                            </Text>
                          </VStack>
                          <Text
                            fontSize={'10px'}
                            fontWeight={600}
                            color='#2C80FF'
                          >
                            {item.cost}
                          </Text>

                        </HStack>
                      })
                    }


                  </VStack>
                </RadioGroup>
              </VStack>
              <VStack
                w={'100%'}
                gap={4}
              >
                <Text w={'100%'}
                  textAlign={'start'}
                  fontWeight={600}
                  color={'black'}
                  fontSize={'md'}
                >
                  Product Add-ons
                </Text>
                <VStack
                  w={'100%'}
                  gap={3}
                >
                  {
                    productAddon_data.map((item, index) => {
                      return <HStack
                        key={index}
                        overflow="hidden"
                        p={5}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        bg="white"
                        w={'100%'}
                        rounded={14}
                      >
                        <Checkbox h={5} w={5}
                        // onChange={()=> handleAddOnChange(item.title)}
                        />
                        <HStack
                          gap={2}
                          w={'80%'}
                        >
                          <Text
                            fontWeight={600}
                            fontSize={'13px'}
                          >
                            {item.title}
                          </Text>
                          <Image src={question_icon} alt='' />
                        </HStack>

                        <Text
                          fontWeight={600}
                          fontSize={'10px'}
                          color='#2C80FF'
                        >
                          {item.cost}
                        </Text>
                      </HStack>
                    })
                  }
                </VStack>
              </VStack>
              <HStack
                w={'100%'}
              >
                <Text
                  fontWeight={600}
                  fontSize={'10px'}
                  w={'8%'}
                >
                  Note :
                </Text>
                <Text
                  fontSize={'10px'}
                  color='rgb(0,0,0,0.5)'
                  w={'92%'}
                >
                  Complex Property lorem ipsum dolor sit amet consectetur. Tempor et purus sed urna risus scelerisque.
                </Text>
              </HStack>
              <Divider
                h={'1px'}
                bg={'#DDDDDD'}
                w={'100%'}
              />
              <HStack
                w={'100%'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Text
                  color={'rgb(0,0,0,0.7)'}
                  fontSize={'sm'}
                  fontWeight={600}
                >
                  Add-on Total
                </Text>
                <Text
                  color={'rgb(0,0,0,0.7)'}
                  fontSize={'sm'}
                  fontWeight={600}
                >
                  $126.00
                </Text>
              </HStack>
              <HStack
                w={'100%'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Text
                  color={'black'}
                  fontSize={'md'}
                  fontWeight={600}
                >
                  Total Amount
                </Text>
                <Text
                  color={'black'}
                  fontSize={'md'}
                  fontWeight={600}
                >
                  $VAR
                </Text>
              </HStack>
              <HStack
                justifyContent={'center'}
                alignItems={'center'}
                gap={4}
                py={3}
                w={'100%'}
              >
                <Button
                  border={'1px solid #2C80FF'}
                  bg={'none'}
                  color={'#2C80FF'}
                  w='180px'
                  h={'45px'}
                  rounded={12}
                >
                  <NavLink to={'/dashboard'}>
                    <Text fontSize={'12px'}>
                      Back to Dashboard
                    </Text>

                  </NavLink>
                </Button>
                <Button
                  rounded={12}
                  bg={'#2C80FF'}
                  color={'#FFFFFF'}
                  w='180px'
                  h={'45px'}
                  _hover={{
                    bg: "#2C80FF",
                    color: "white"
                  }}
                  onClick={handleSubmit}
                >
                  <Text fontSize={'12px'}>
                    Confirm & Checkout
                  </Text>

                </Button>
              </HStack>
            </VStack>
          </HStack>

        </Flex>
      </Flex>
      {ViewDuedate && <DueDate setViewDuedate={setViewDuedate} Inputs={Inputs} />}
    </>
  )
}


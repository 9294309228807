import React, { useState, useEffect} from 'react'
import { useMemo } from 'react';
import { Box, HStack, Input, Text, VStack, Button, Flex} from '@chakra-ui/react';
import { Link} from 'react-router-dom';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import ChatContainer from './ChatContainer'
const Support = () => {
  const [selected, setselected] = useState('My Properties');
  const [openchatcontainer, setopenchatcontainer] = useState(false)
  const faqs = [
    {
      category: "My Properties",
      questions: [
        {
          question: "How do I delete a property?",
          isopen: false,
          answer:
            "To delete a property, navigate to the 'My Properties' section and select the property you want to delete. Click on the 'Delete' option and confirm your choice.",
        },
        {
          question: "Can I add multiple images to a property listing?",
          isopen: false,
          answer:
            "Yes, you can add multiple images to showcase your property. While adding or editing a property, use the 'Upload Image' feature to include additional pictures.",
        },
        {
          question: "Is there a limit to the number of properties I can list?",
          isopen: false,
          answer:
            "Currently, there is no limit to the number of properties you can list on our platform. Feel free to add as many properties as you need.",
        }
      ],
    },
    {
      category: "My Orders",
      questions: [
        {
          question: "Can I cancel my order after placing it?",
          isopen: false,
          answer:
            "The ability to cancel an order depends on its current status. Please check the 'My Orders' page for the specific order's status and follow the provided instructions for cancellations.",
        },
        {
          question: "What do I do if there is an issue with my delivered order?",
          isopen: false,
          answer:
            "If you encounter any issues with your delivered order, such as missing items or damages, contact our customer support immediately with your order details for assistance.",
        },
      ],
    },
    {
      category: "Billing",
      questions: [
        {
          question: "How can I view my billing history?",
          isopen: false,
          answer:
            "To view your billing history, go to the 'Billing' section in your account settings. There, you can find a detailed history of your past transactions and invoices.",
        },
        {
          question: "Do you offer automatic billing for subscriptions?",
          isopen: false,
          answer:
            "Yes, we provide automatic billing for subscription services. You can set up automatic payments in the 'Billing' section by linking a valid payment method.",
        },
        {
          question:
            "What should I do if I notice an incorrect charge on my statement?",
          isopen: false,
          answer:
            "If you identify an incorrect charge on your statement, please contact our billing department with the relevant details. We will investigate and resolve the issue promptly.",
        }
      ],
    },
    {
      category: "Others",
      questions: [
        {
          question:
            "How can I change my email address associated with the account?",
          isopen: false,
          answer:
            "To change your email address, go to the 'Account Settings' page and update the email field with your new address. Verify the change through the confirmation link sent to your new email.",
        },
        {
          question: "Is there a mobile app available for your services?",
          isopen: false,
          answer:
            "Yes, we offer a mobile app for a seamless experience. You can download it from the App Store or Google Play and log in using your existing account credentials.",
        },
      ],
    },
  ];
  const [filterFAQ, setfilterFAQ] = useState([])
  const memoizedFaqs = useMemo(() => faqs, [faqs]);

  useEffect(() => {
      setfilterFAQ(memoizedFaqs.find((faq) => faq.category === selected)?.questions);
  }, [selected]);
  return (
    <>
    {
      openchatcontainer ?
      <ChatContainer setopenchatcontainer={setopenchatcontainer}/> : 
      <Flex
      // h={'85vh'}
      w='100%'
      py={3}
      my={2}
      px={20}
      justifyContent={'center'}
      alignItems={'center'}
  >
      <Box
      w={'100%'}
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      border={'1px solid #FFFFFF'}
      boxShadow='15px 15px 60px rgba(0, 0, 0, 0.1)'
      borderRadius="24px"
      bg={'rgba(242, 242, 250, 0.7)'}
      py={5}
      px={10}
      flexDirection={'column'}
    >
      <HStack
        w={'100%'}
        justifyContent={'space-between'}
        my={2}
      >
        <VStack>
          <Text fontSize={'lg'}
            fontWeight={700}
            w={'100%'}
            textAlign={'left'}
          >
            Help & Support
          </Text>
          <Text
            fontSize={'12px'}
            color={'rgba(0, 0, 0, 0.5)'}
            w={'100%'}
            textAlign={'left'}
          >Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Text>
        </VStack>
        <Link to={'/'}
        >
          <Text w='100%'
            textAlign='end'
            color='#2C80FF'
            fontWeight={600}
          >
            Back</Text>
        </Link>
      </HStack>
      <VStack
        justifyContent='space-between'
        alignItems='center'
        py={3}
        spacing={3}
        mb={2}
      >
        <Text fontWeight={700}
          fontSize={'xl'}
        >
          Hello, how can we help?
        </Text>
        <HStack
          // w={'30%'}
          minW={'50vh'}
          maxW={'80vh'}
          gap={0}
          borderRadius={'xl'}
          bg={'white'}
        >
          <Input
            type='text'
            placeholder='Ask a question'
            bg={'white'}
            border={'none'}
            color={'grey.600'}
            fontSize={'sm'}
            _focusVisible={
              {
                border: 'none'
              }
            }
          />
          <Button
            w={'180px'}
            borderRadius={'xl'}
            bg='#2C80FF'
            color={'white'}
            fontSize={'sm'}
            border='none'
            _hover={
              {
                bg: '#2C80FF',
                color: 'white'
              }
            }
          >
            Search
          </Button>
        </HStack>

      </VStack>
      <VStack w={'100%'}>
        <HStack
          w={'100%'}
          px={40}
          minH={'10vh'}
          justifyContent={'space-evenly'}
          alignItems={'center'}
        >
          {faqs.map((faq, index) => (
            <VStack key={index}
              onClick={() => setselected(faq.category)}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Text
                fontSize={'sm'}
                fontWeight={600}
                _hover={
                  {
                    cursor: 'pointer'
                  }
                }
                color={selected === faq.category ? "#2593D6" : "gray"}
              >
                {faq.category}
              </Text>
              <Box
                h={'2.5px'}
                w={'100%'}
                rounded={20}
                m={0}
                p={0}
                bg={selected === faq.category ? "#2593D6" : "none"}
              />
            </VStack>
          ))}

        </HStack>
        <VStack w={'90%'}
          justifyContent={'flex-start'}
          spacing={4}
          alignItems={'flex-start'}
          py={2}
          minH={'30vh'}
          maxH={'40vh'}
        >
          {filterFAQ.map((question, index) => (
            <HStack
              w={'100%'}
              justifyContent={'space-between'}
              alignItems={'flex-start'}
              pb={4}
              borderBottom={filterFAQ.indexOf(question) ===
                filterFAQ.length - 1 ? "none" : "1px solid #E5E5E5"}
            >
              <HStack w='90%' justifyContent={'flex-start'} alignItems={'flex-start'}>
                <GoDotFill />
                <Box>
                  <Text
                    mx={3}
                    fontSize={'sm'}
                    fontWeight={'600'}
                    mb={1}
                  >
                    {question.question}
                  </Text>
                  {question.isOpen && (
                    <Text
                      fontSize='sm'
                      color="#71706F"
                      mx={3}
                      mb={1}
                    >
                      {question.answer}
                    </Text>
                  )}
                </Box>
              </HStack>
              {!question.isOpen ? (
                <FaPlus
                  width={'10%'}
                  onClick={() => {
                    const copydata = [...filterFAQ]
                    setfilterFAQ(copydata.map((itm, indexs) => ({
                      ...itm,
                      isOpen: index === indexs ? true : false
                    })))
                  }}
                />
              ) : (
                <FaMinus
                  width={'10%'}
                  onClick={() => {
                    const copydata = [...filterFAQ]
                    setfilterFAQ(copydata.map((itm, indexs) => ({
                      ...itm,
                      isOpen: false
                    })))
                  }}
                />
              )}
            </HStack>
          ))}
        </VStack>
      </VStack>
      <VStack spacing={1}
        my={1}
        py={1}
      >
        <Text fontSize={'xl'}
          fontWeight={700}>
          Still need help?
        </Text>
        <Button w={'240px'}
          bg={'#2C80FF'}
          color='white'
          rounded={10}
          fontSize={'sm'}
          border='none'
          _hover={
            {
              bg: '#2C80FF',
              color: 'white'
            }
          }
          onClick={()=>setopenchatcontainer(true)}
        >
          Talk to Support
        </Button>
      </VStack>
    </Box>
    </Flex>
    }
    </>
  )
}

export default Support
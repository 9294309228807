import { Box, Container, HStack, Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import user_icon from '../../assets/user-polygon-icon.svg'
import support_icon from '../../assets/support-icon.svg'
import bills_icon from '../../assets/bills-icon.svg'
import logout_icon from '../../assets/logout-icon.svg'
import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
const PropfilePopup = () => {
  const { viewProfilePopup, setuserId, setLastName, setuserMail, setFirstName, setUser, userMail, setToken, setviewProfilePopup, FirstName, LastName } = useContext(AuthContext);
  const UserName = `${FirstName.charAt(0)}${LastName.charAt(0)}`;
  const navigate = useNavigate()
  return (
    <Container
      h={'100vh'} maxW={'100vw'} display={'flex'}
      position={'absolute'}
      top={0}
      left={0}
      justifyContent={'flex-end'}
      bgColor={'rgba(0, 0, 0, 0.3)'}
      pt={'9vh'}
      pr={'5vw'}
      onClick={() => setviewProfilePopup(!viewProfilePopup)}
    >
      <VStack
      maxH={['41vh','41vh','41vh','41vh','41vh','35vh']}
      w={['20vw', '20vw','20vw','20vw','20vw','17vw']}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        pt={'3'}
        pb={'5'}
        pl={'4'}
        pr={'3'}
        rounded={15}
        bg={'white'}
      >
        <HStack
          w={'100%'}
          gap={2}
          py={2}
        >
          <Box h={'55px'} 
          w={'55px'} 
          display={'flex'}
            alignItems={'center'}
            color={'#2C80FF'}
            fontWeight={600}
            justifyContent={'center'} 
            rounded={'full'}
            boxShadow={'0px 3px 6px rgba(0, 0, 0, 0.16)'}
            
            onClick={() => setviewProfilePopup(!viewProfilePopup)}
          >
           <Text
           fontSize={'22px'}
           textAlign={'center'}
           w={'100%'}
           >{UserName}
            </Text> 
          </Box>
          <VStack
          gap={1}
          justifyContent={'center'}
          alignItems={'center'}
          >
            <Text fontSize={'15px'}
              fontWeight={600}
              w={'100%'}
              textAlign={'start'}
            >
              {FirstName}&nbsp;{LastName}
            </Text>
            <Text fontSize={'x-small'}
              color={'grey'}
              w={'100%'}
              textAlign={'start'}
            >
              {userMail}
            </Text>
          </VStack>
        </HStack>
        <Box
          bg={'rgba(101, 106, 114, 0.4)'}
          h={'1px'}
          w={'70%'}
        />
        <HStack py={2} w='90%'
          _hover={
            {
              cursor: 'pointer'
            }
          }
          pl={'2'}
          onClick={() => setviewProfilePopup(!viewProfilePopup)}
          gap={3}
        >
          <Image src={user_icon} alt='' />
          <Text fontSize={'sm'}>My Profile</Text>
        </HStack>
        <HStack py={2} w='90%'
          _hover={
            {
              cursor: 'pointer'
            }
          }
          gap={3}
          pl={'2'}
          onClick={() => setviewProfilePopup(!viewProfilePopup)}
        >
          <Image src={bills_icon} alt='' />
          <Text fontSize={'sm'}>Billing & Payment</Text>
        </HStack>
        <HStack py={2} w='90%'
          _hover={
            {
              cursor: 'pointer'
            }
          }
          gap={3}
          pl={'2'}
          onClick={() => {
            setviewProfilePopup(!viewProfilePopup)
            navigate('/get-support')
          }}
        >
          <Image src={support_icon} alt='' />
          <Text fontSize={'sm'}>Get Support</Text>
        </HStack>
        <HStack py={2} w='90%'
          gap={3}
          pl={'2'}
          onClick={() => {
            setUser(false)
            setToken(null);
            setuserId(null)
            setuserMail('')
            setFirstName('')
            setLastName('')
            localStorage.removeItem("userId");
            localStorage.removeItem("token");
            localStorage.removeItem('userMail')
            localStorage.removeItem('firstName')
            localStorage.removeItem('lastName')
            toast.success("logout successfully")
            navigate('/')
            setviewProfilePopup(!viewProfilePopup)
          }}
          _hover={
            {
              cursor: 'pointer'
            }
          }
        >
          <Image src={logout_icon} alt=''
          />
          <Text fontSize={'sm'}>Logout</Text>
        </HStack>
      </VStack>
    </Container>
  )
}

export default PropfilePopup
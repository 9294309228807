import { Card, Container, Button, HStack, Image, Input, Text, Textarea } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import close_btn from '../../assets/close-btn.png'
import axios from 'axios'
import { url } from '../../config'
import { AuthContext } from '../../context/AuthContext'
import { toast } from 'react-toastify'
const CreateTicket = ({ setviewCreateTicket, fetchTickets }) => {

    const { Token } = useContext(AuthContext)
    const [Inputs, setInputs] = useState({
            ticketTitle: "",
            Subject: "",
            ticketdesc: ""
        })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs({ ...Inputs, [name]: value })
    }

    const AddTicket = async (e) => {
        e.preventDefault();
        if (!Inputs.ticketTitle || !Inputs.Subject || !Inputs.ticketdesc) {
            toast.error("Fields Cant be Empty")
            return;
        }
        try {
            const res = await axios.post(`${url}/user/ticket`, Inputs, {
                headers: {
                    Authorization: `Bearer ${Token}`
                }
            })
            if(res){
               toast.success(res.data.message)
                   fetchTickets()
                    setInputs({ ticketTitle: "", Subject: "", ticketdesc: "" })
                    setviewCreateTicket(false) 
            }
            else{
                toast.error("error while adding ticket")
            }        
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Container
            h={'100vh'} maxW={'100vw'} display={'flex'} justifyContent={'center'}
            alignItems={'center'}
            position={'absolute'}
            top={0}
            left={0}
            bgColor={'rgba(0, 0, 0, 0.3)'}
        >
            <Card bg={'white'}
                px={5}
                py={6}
                h={'55vh'}
                w={'35vw'}
                justifyContent={'space-between'}
                alignItems={'center'}
                rounded={12}
                flexDirection={'column'}
                gap={2}
            >
                <HStack
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    w={'100%'}
                >
                    <Text fontSize={'sm'}
                        color={'black'}
                        textAlign={'center'}
                        w={'100%'}
                    >
                        Create Ticket
                    </Text>
                    <Image src={close_btn} alt=''
                        onClick={() => setviewCreateTicket(false)}
                        _hover={{ cursor: 'pointer' }}
                        h={10}
                        w={10}
                    />
                </HStack>
                <Input type='text'
                    placeholder='Ticket Title'
                    fontSize={'12px'}
                    bg={'#F2F2F2'}
                    border={'none'}
                    _focusVisible={
                        { border: 'none' }
                    }
                    name='ticketTitle'
                    value={Inputs.ticketTitle}
                    onChange={handleChange}
                />
                <Text
                    fontSize={'12px'}
                    color={'grey'}
                    w={'100%'}
                    textAlign={'left'}
                >
                    select subject
                </Text>
                <Input type='text'
                    placeholder='Ticket Subject'
                    fontSize={'12px'}
                    bg={'#F2F2F2'}
                    border={'none'}
                    _focusVisible={
                        { border: 'none' }
                    }
                    value={Inputs.Subject}
                    onChange={handleChange}
                    name='Subject'
                />
                <Textarea type='text'
                    placeholder='Ticket Description'
                    fontSize={'12px'}
                    bg={'#F2F2F2'}
                    border={'none'}
                    _focusVisible={
                        { border: 'none' }
                    }
                    name='ticketdesc'
                    value={Inputs.ticketdesc}
                    onChange={handleChange}
                />
                <Button
                    rounded={8}
                    bg='#2C80FF'
                    color='white'
                    border='none'
                    _hover={
                        {
                            bg: '#2C80FF',
                            color: 'white',
                            border: 'none',
                        }
                    }
                    w='180px'
                    fontSize={'sm'}
                    onClick={AddTicket}
                >
                    Send Message
                </Button>

            </Card>
        </Container>
    )
}

export default CreateTicket
import { Checkbox, HStack, Text, VStack, Flex, Image, Button } from '@chakra-ui/react';
import React, { useState, useContext } from 'react';
import property_icon from '../../assets/add-single-property-icon.png';
import user_icon from '../../assets/user_icon.png';
import clock_icon from '../../assets/clock_icon.png';
import property_type_icon from '../../assets/property_type_icon.png';
import link_icon from '../../assets/link_icon.png';
import dots_icon from '../../assets/dots_icon.png';
import { AuthContext } from '../../context/AuthContext';
import { RiEdit2Line } from "react-icons/ri";
import axios from 'axios';
import { url } from '../../config';
import { MdOutlineCloudDownload } from "react-icons/md";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Attachments from './Attachments';
export default function Card(props) {
  const { FirstName, LastName, selectedArray, Token, viewAttachments, setviewAttachments, handleCardClick, ArchieveProperties, fetchProperties, setselectedArray } = useContext(AuthContext);
  const [isHover, setisHover] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  // const [isChecked, setIsChecked] = useState(false);
  const [showMenu, setshowMenu] = useState(false);

  const navigate = useNavigate();

  const time = props.item.updatedAt;
  const formatDate = (time) => {
    const date = new Date(time);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = String(date.getUTCFullYear()).slice(-2);

    return `${day}/${month}/${year}`;
  };

  const handleUnArchive = async () => {
    try {
      const res = await axios.post(`${url}/client/unArchive`, [`${props.item._id}`], {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      if (res) {
        fetchProperties();
        ArchieveProperties();
        toast.success(res.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const formattedDate = formatDate(time);

  const handleViewDetails = (card) => {
    setSelectedCard(card);
    setviewAttachments(true);
  };

  return (
    <>
      {props.gridView ? (
        <VStack
          gap={0}
          h={'332px'}
          w={'290px'}
          onMouseEnter={() => setisHover(true)}
          onMouseLeave={() => setisHover(false)}
          position={'relative'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          py={'2'}
        >
          <VStack
            h={'272px'}
            w={'270px'}
            bg={'#FFFFFF'}
            rounded={14}
            p={3}
            justifyContent={'space-between'}
            alignItems={'center'}
            _hover={{
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 3px 10px',
              cursor: 'pointer',
            }}
          >
            <HStack h={'25%'} w={'95%'} justifyContent={'flex-start'} alignItems={'flex-start'}>
              <VStack h={'100%'} w={'90%'} justifyContent={'center'} alignItems={'flex-start'} gap={1}>
                <Text fontSize={'smaller'} fontWeight={600}>
                  {props.item.address}
                </Text>
                <Text fontSize={'small'}>
                  {props.item.state}, {props.item.city}, {props.item.zipcode}
                </Text>
              </VStack>
              <Checkbox width={'10%'} mt={1}
                isChecked={selectedArray.some(selectedCard => selectedCard._id === props.item._id)}
                onChange={() => handleCardClick(props.item)} />
            </HStack>
            <VStack h={'70%'} w={'95%'} justifyContent={'space-between'} alignItems={'flex-start'}>
              <Flex gap={2}>
                <Image src={property_icon} alt='' w={'10%'} />
                <Text fontSize={'small'}>Property ID: {props.item.aptunit}</Text>
              </Flex>
              <HStack h={'20%'} w={'100%'} justifyContent={'space-between'} alignItems={'flex-start'}>
                <Flex gap={2} justifyContent={'flex-start'} h={'100%'} w={'50%'} alignItems={'center'}>
                  <Image src={clock_icon} alt='' w={'20%'} h={'60%'} />
                  <Text fontSize={'small'}>
                    <Text>{formattedDate}</Text>
                  </Text>
                </Flex>
                <Flex gap={2} justifyContent={'flex-start'} h={'100%'} w={'50%'} alignItems={'center'}>
                  <Image src={property_type_icon} alt='' w={'20%'} h={'60%'} />
                  <Text fontSize={'small'}>{props.item.Propertytype}</Text>
                </Flex>
              </HStack>
              <Flex gap={2}>
                <Image src={user_icon} alt='' w={4} />
                <Text fontSize={'small'}>
                  {props.item.toclient ? `${FirstName} ${LastName}` : 'NA'}
                </Text>
              </Flex>
              <HStack w={'100%'} h={'30%'} justifyContent={'space-between'} alignItems={'center'}>
                <Flex gap={3}
                  onClick={() => {
                    handleViewDetails(props.item)
                    console.log(selectedCard)
                  }}
                >
                  <Image src={link_icon} alt='' h={5} />
                  <Text fontSize={'small'} color={'#2C80FF'} fontWeight={600}>
                    view attachments
                  </Text>
                </Flex>
                <Image src={dots_icon} alt=''
                  onClick={() => setshowMenu(!showMenu)}
                />
              </HStack>
            </VStack>
            {
              showMenu && <>
                <VStack bg='white'
                  rounded={'12'}
                  position={'absolute'}
                  boxShadow='rgba(0, 0, 0, 0.35) 0px 3px 10px'
                  p={2}
                  px={5}
                  py={3}
                  w={'60%'}
                  top="76%"
                  right="10%"
                  zIndex={1}
                  gap={1}
                >
                  <HStack justifyContent={'space-between'}
                    alignItems={'center'}
                    w={'100%'}
                  >

                    <Text w={'100%'}
                      textAlign={'start'}
                      fontSize={'12px'}
                    >
                      edit </Text>
                    <RiEdit2Line size={20} />
                  </HStack>
                  <HStack justifyContent={'space-between'}
                    alignItems={'center'}
                    w='100%'
                  >
                    <Text
                      w={'100%'}
                      textAlign={'start'}
                      fontSize={'12px'}
                    >
                      download </Text>
                    <MdOutlineCloudDownload size={20} />
                  </HStack>
                </VStack>
              </>
            }
          </VStack>
          {isHover ? (
            <Flex
              h={'43px'}
              w={'210px'}
              justifyContent={'center'}
              alignItems={'flex-start'}>
              {!props.item.is_active ?
                <Button
                  w={'100%'}
                  bg={'#2C80FF'}
                  h={'100%'}
                  borderRadius={'0px 0px 8px 8px'}
                  color={'#FFFFFF'}
                  fontSize={'sm'}
                  _hover={{
                    bg: 'rgba(40, 125, 253, 1)',
                  }}
                  fontWeight={500}
                  onClick={handleUnArchive}
                >
                  Un-archive
                </Button> :
                <Button
                  w={'100%'}
                  bg={'#2C80FF'}
                  h={'100%'}
                  borderRadius={'0px 0px 8px 8px'}
                  color={'#FFFFFF'}
                  fontSize={'sm'}
                  _hover={{
                    bg: 'rgba(40, 125, 253, 1)',
                  }}
                  fontWeight={500}
                  onClick={() => {
                    handleCardClick(props.item)
                    navigate('order-appraisal')
                  }}
                >
                  Create an order
                </Button>}
            </Flex>
          ) : (
            ''
          )}
        </VStack>
      ) : (
        <Flex
        minH={'110px'}
        w={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        p={2}
        >
        <VStack
          minH={'100px'}
          w={'100%'}
          bg={'#FFFFFF'}
          rounded='15'
          p={4}
          justifyContent={'space-between'}
          alignItems={'flex-end'}
          _hover={{
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 3px 10px',
          }}
        >
          <HStack justifyContent={'space-between'} alignItems={'center'} w={'100%'} h={'100%'}>
            <HStack>
              <Checkbox
              isChecked={selectedArray.some(selectedCard => selectedCard._id === props.item._id)} 
              onClick={() => handleCardClick(props.item)}
              />
              <Text fontSize={'12px'} fontWeight={600}>
                {props.item.address},
              </Text>
              <Text fontSize={'12px'}>
                {props.item.state}, {props.item.city}, {props.item.zipcode}
              </Text>
            </HStack>
            <Flex gap={2}
              onClick={() => setviewAttachments(true)}
            >
              <Image src={link_icon} alt='' h={4} />
              <Text fontSize={'10px'} color={'#2C80FF'}>
                view attachments
              </Text>
            </Flex>
          </HStack>

          <HStack bg={'#FAFAFA'} rounded={12} w={'98%'} h={'45px'} px={3} py={2} justifyContent={'space-between'}>
            <Flex gap={2}>
              <Image src={property_icon} alt='' h={5} />
              <Text fontSize={'12px'}>Property ID: {props.item.aptunit}</Text>
            </Flex>
            <Flex gap={2} justifyContent={'flex-start'} alignItems={'center'}>
              <Image src={clock_icon} alt='' h={4} />
              <Text fontSize={'12px'}>
                <Text>{formattedDate}</Text>
              </Text>
            </Flex>
            <Flex gap={2}>
              <Image src={user_icon} alt='' h={4} />
              <Text fontSize={'12px'}>
                {props.item.toclient ? `${FirstName} ${LastName}` : 'NA'}
              </Text>
            </Flex>
            <Flex gap={2} justifyContent={'flex-start'} alignItems={'center'}>
              <Image src={property_type_icon} alt='' h={4} />
              <Text fontSize={'12px'}>{props.item.Propertytype}</Text>
            </Flex>
            <Image src={dots_icon} alt='' w={'20px'} />
          </HStack>
        </VStack>
        </Flex>
      )}

      {viewAttachments && selectedCard && <Attachments setviewAttachments={setviewAttachments} property={selectedCard} />}
    </>
  );
};


import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';
import { url } from '../config';
import { toast } from 'react-toastify';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userId, setuserId] = useState(localStorage.getItem('userId') || '');
  const [Token, setToken] = useState(localStorage.getItem('token') || '');
  const [User, setUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [gridView, setgridView] = useState(true)
  const [viewAddProperty, setviewAddProperty] = useState(false);
  const [PropertiesArray, setPropertiesArray] = useState([]);
  const [Verifyemail, setVerifyemail] = useState('');
  const [FirstName, setFirstName] = useState(localStorage.getItem('firstName') || '');
  const [LastName, setLastName] = useState(localStorage.getItem('lastName') || '');
  const [userMail, setuserMail] = useState(localStorage.getItem('userMail') || '');
  const [viewProfilePopup, setviewProfilePopup] = useState(false);
  const [addtoArchieve, setaddtoArchieve] = useState(false);
  const [ArchiveArray, setArchiveArray] = useState([]);
  const [OrdersArray, setOrdersArray] = useState([]);
  const [viewaddOrder, setviewaddOrder] = useState(false);
  const [propertyId, setpropertyId] = useState('');
  const [selectedArray, setselectedArray] = useState([]);
  const [selectedCardIds, setselectedCardIds] = useState([]);
  const [ViewDuedate, setViewDuedate] = useState(false); 
  const [viewAttachments, setviewAttachments] = useState(false);
  useEffect(() => {
    const restoreSession = async () => {
      if (Token) {
        try {
          const response = await axios.get(`${url}/client/property`, {
            headers: {
              Authorization: `Bearer ${Token}`,
            },
          });
          const Archiveres = await axios.get(`${url}/client/archive`, {
            headers: {
              Authorization: `Bearer ${Token}`,
            },
          });
          setArchiveArray(Archiveres.data.data)
          setPropertiesArray(response.data.data);
          setUser(true);
          console.log(response.data.data);
        } catch (error) {
          console.error('Error restoring session', error);
          setUser(false);
        }
      }
      setLoading(false);
    };

    restoreSession();
  }, [Token]);

  const ArchieveProperties = async () => {
    try {
      const response = await axios.get(`${url}/client/archive`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      setArchiveArray(response.data.data);
      // console.log(response.data.data);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };

  const fetchProperties = async () => {
    try {
      const response = await axios.get(`${url}/client/property`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      setPropertiesArray(response.data.data);
      // console.log(response.data.data);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };

  const handleCardClick = (card) => {
    setselectedArray(prevSelected => 
      prevSelected.some(selectedCard => selectedCard._id === card._id) 
        ? prevSelected.filter(selectedCard => selectedCard._id !== card._id) 
        : [...prevSelected, card]
    );
  };

  const handleArchive = async () => {
    if (selectedArray.length === 0) {
      toast.error('Select the cards to archive!');
      return;
    }
      const Ids = selectedArray.map(card => card._id);
      setselectedCardIds(Ids)
    try {
      const res = await axios.post(`${url}/client/unArchive`,selectedCardIds, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      if (res) {
        fetchProperties();
        ArchieveProperties();
        setselectedCardIds([])
        setselectedArray([])
        toast.success(res.data.message);
      }
    } catch (error) {
      console.error(error);
    } 
    };

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${url}/client/orders`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      setOrdersArray(response.data.data);
      // console.log(response.data.data);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };

  useEffect(() => {
    if (Token) {
      localStorage.setItem('token', Token);
    }
    if (userId) {
      localStorage.setItem('userId', userId);
    }
    if (userMail) {
      localStorage.setItem('userMail', userMail);
    }
    if (FirstName) {
      localStorage.setItem('firstName', FirstName);
    }
    if (LastName) {
      localStorage.setItem('lastName', LastName);
    }
  }, [Token, userId, userMail, FirstName, LastName]);

  return (
    <AuthContext.Provider
      value={{
        User,
        setUser,
        viewAddProperty,
        setviewAddProperty,
        userId,
        setuserId,
        PropertiesArray,
        setPropertiesArray,
        Verifyemail,
        setVerifyemail,
        viewProfilePopup,
        setviewProfilePopup,
        Token,
        setToken,
        FirstName,
        setFirstName,
        LastName,
        setLastName,
        userMail,
        setuserMail,
        loading,
        addtoArchieve,
        setaddtoArchieve,
        gridView,
        setgridView,
        ArchiveArray,
        setArchiveArray,
        ArchieveProperties,
        fetchProperties,
        fetchOrders,
        OrdersArray,
        setOrdersArray,
        viewaddOrder,
        setviewaddOrder,
        propertyId,
        setpropertyId,
        selectedArray,
        setselectedArray,
         handleCardClick,
         handleArchive,
         selectedCardIds,
         ViewDuedate,
         setViewDuedate,
         viewAttachments,
         setviewAttachments
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

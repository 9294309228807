// import React from 'react'
import { Container,Button, Card, HStack, VStack, Image, Text } from '@chakra-ui/react'
// import OrdersCalender from './OrdersCalender'
import React, { useState } from "react";
import axios from 'axios';
// import { url } from '../../../config';
import close_icon from '../../../assets/close-btn.png';
import OrdersCalender from './OrdersCalender';
// import { toast } from 'react-toastify';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
export default function DueDate({ setViewDuedate, Inputs, setInputs, Ids, setselectedArray, Token }) {
  // const [selectedDate, setSelectedDate] = useState(null);
  // const toast = useToast();

  // // Handle form submission
  // const handleSubmit = () => {
  //   if (selectedDate) {
  //     toast({
  //       title: "Date Selected",
  //       description: `You have selected: ${selectedDate.toLocaleDateString()}`,
  //       status: "success",
  //       duration: 3000,
  //       isClosable: true,
  //     });
  //   } else {
  //     toast({
  //       title: "No Date Selected",
  //       description: "Please select a date before submitting.",
  //       status: "error",
  //       duration: 3000,
  //       isClosable: true,
  //     });
  //   }
  // };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const res = await axios.post(`${url}/client/orders`, [Inputs], {
  //       headers: {
  //         'Authorization': `Bearer ${Token}`
  //       }
  //     }
  //     )
  //     if (res) {
  //       toast.success("Order appraisal is added")
  //       setselectedArray([])
  //       setInputs({
  //         address: "",
  //         apt_unit: "",
  //         city: "",
  //         state: "",
  //         zipcode: "",
  //         reason: "",
  //         add_info: "",
  //         type: "",
  //         add_on: "Rental Grid",
  //         property_id: Ids
  //       })
  //     }
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }
  return (
    <Container h={'100vh'} maxW={'100vw'} display={'flex'} justifyContent={'center'}
      alignItems={'center'}
      position={'absolute'}
      top={0}
      left={0}
      bgColor={'rgba(0, 0, 0, 0.3)'}
      zIndex={4}
    >
      <Card h={'80%'} w={'40%'} 
      bgColor={'white'}
        justifyContent={'center'}
        alignItems={'center'}
        boxShadow={'0px 20px 20px #0000000B'}
        borderRadius={'15px'}
      >
        <VStack h={'100%'} w={'100%'} p={4}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <HStack
            justifyContent={'space-between'}
            alignItems={'flex-start'}
            w={'100%'}
          >
            <VStack w={'95%'}
              justifyContent={'center'}
              alignItems={'center'}
              pl={8}
            >
              <Text
                fontWeight={600}
                fontSize={'sm'}
                h={'100%'}
                w={'95%'}
                textAlign={'center'}
              >
                Select Order Due Date
              </Text>
              <Text
                fontWeight={400}
                fontSize={'12px'}
                h={'100%'}
                w={'70%'}
                textAlign={'center'}
              >
                Make sure this is just for user info it's may take more time in processing
              </Text>
            </VStack>

            <Image src={close_icon} alt=''
              h={10}
              w={10}
              _hover={{
                cursor: 'pointer'
              }}
              onClick={() => setViewDuedate(false)}
            />
          </HStack>
          <OrdersCalender/>
          <Button
            rounded={12}
            bg={'#2C80FF'}
            color={'#FFFFFF'}
            w='70%'
            h={'45px'}
            // onClick={handleSubmit}
          >
            <Text fontSize={'12px'}>
              Select Date
            </Text>

          </Button>
        </VStack>
      </Card>
    </Container>
  )
}


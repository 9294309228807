import React, { useContext } from 'react'
import { Container, Card, VStack, HStack, Image, Text, Button } from '@chakra-ui/react'
import close_icon from '../../assets/close-btn.png';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
export default function NewOrderPopup() {
    const { setviewaddOrder } = useContext(AuthContext)
    const navigate = useNavigate()
    return (
        <Container h={'100vh'} maxW={'100vw'} display={'flex'} justifyContent={'center'}
            alignItems={'center'}
            position={'absolute'}
            top={0}
            left={0}
            bgColor={'rgba(0, 0, 0, 0.3)'}
        >

            <Card h={'48%'} w={'42%'} bgColor={'#FFFFFF'}
                justifyContent={'center'}
                alignItems={'center'}
                boxShadow={'0px 20px 20px #0000000B'}
                borderRadius={'15px'}
            >
                <VStack h={'100%'} w={'100%'} p={4}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <HStack h={'8%'} w={'100%'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Text
                            fontWeight={600}
                            fontSize={'sm'}
                            h={'100%'}
                            w={'95%'}
                            textAlign={'center'}
                            pl={8}
                        >
                            Property Added Successfully
                        </Text>
                        <Image src={close_icon} alt=''
                            h={10}
                            w={10}
                            _hover={{
                                cursor: 'pointer'
                            }}
                            onClick={() => setviewaddOrder(false)}
                        />
                    </HStack>
                    <VStack
                        h={'60%'}
                        w={'100%'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        color={'black'}
                        gap={5}
                    >
                        <Text>
                            Successfully Added !
                        </Text>
                        <Text
                            fontSize={'11px'}
                        >
                            Lorem Ipsum is simply dummy text of the printing
                        </Text>
                    </VStack>
                    <HStack
                        justifyContent={'center'}
                        alignItems={'center'}
                        gap={4}
                        py={3}
                        w={'100%'}
                    >
                        <Button
                            border={'1px solid #2C80FF'}
                            bg={'none'}
                            color={'#2C80FF'}
                            w='200px'
                            h={'45px'}
                            rounded={12}
                            onClick={() => {
                                setviewaddOrder(false)
                                navigate('/property-form')
                            }}
                        >
                            <Text fontSize={'12px'}>
                                Add another property
                            </Text>
                        </Button>
                        <Button
                            rounded={12}
                            bg={'#2C80FF'}
                            color={'#FFFFFF'}
                            w='200px'
                            h={'45px'}
                            _hover={{
                                bg: "#2C80FF",
                                color: "white"
                            }}
                            onClick={() => {
                                setviewaddOrder(false)
                                navigate('/order-appraisal')
                            }}
                        >
                            <Text fontSize={'12px'}>
                                Order New Appraisal
                            </Text>
                        </Button>
                    </HStack>
                </VStack>
            </Card>
        </Container>
    )
}

import React from 'react'
import { Container, Card, Flex, VStack, HStack, Box, Image, Text } from '@chakra-ui/react'
import close_icon from '../../assets/close-btn.png';
const Attachments = ({ setviewAttachments, property }) => {
    return (
        <Container h={'100vh'} maxW={'100vw'} display={'flex'} justifyContent={'center'}
            alignItems={'center'}
            position={'absolute'}
            top={0}
            left={0}
            bgColor={'rgba(0, 0, 0, 0.3)'}
            zIndex={3}
        >
            <Card h={'48%'} w={'40%'} bgColor={'#FFFFFF'}
                justifyContent={'center'}
                alignItems={'center'}
                boxShadow={'0px 20px 20px #0000000B'}
                borderRadius={'15px'}
            >
                <VStack h={'100%'} w={'100%'} p={4}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <HStack
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        w={'100%'}
                    >
                        <Text
                            fontWeight={600}
                            fontSize={'sm'}
                            h={'100%'}
                            w={'95%'}
                            textAlign={'center'}
                            pl={8}
                        >
                            Attachments List
                        </Text>
                        <Image src={close_icon} alt=''
                            h={10}
                            w={10}
                            _hover={{
                                cursor: 'pointer'
                            }}
                            onClick={() => setviewAttachments(false)}
                        />
                    </HStack>
                    <Text
                        w={'100%'}
                        textAlign={'start'}
                        fontWeight={600}
                        fontSize={'13px'}
                    >
                        All Images
                    </Text>
                    <Flex gap={2}
                        pl={5}
                    >
                        {/* {console.log(property.images)}
                        {property.images && property.images.map((image, index) => (
                            <Box key={index} p={0} rounded={12}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                bg={'white'}
                                position={'relative'} height={'120px'} width={'110px'}>
                                <Image src={image} alt={`Selected image ${index + 1}`}
                                    objectFit="cover"
                                    h={'110px'}
                                    w={'100px'}
                                    rounded={12}
                                />
                            </Box>
                        ))} */}

                    </Flex>

                </VStack>
            </Card>
        </Container>
    )
}

export default Attachments
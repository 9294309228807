import React, { useContext } from 'react'
import { Flex, Box, VStack } from '@chakra-ui/react'
import Card from '../../common/Card'
import { AuthContext } from '../../../context/AuthContext'
import DashboardNavbar from '../../common/DashboardNavbar'
const ArchieveList = ({ selected }) => {

  const { ArchiveArray, gridView } = useContext(AuthContext)

  return (
    <VStack
      h={'90%'}
      w={'93%'}
      maxW={'93%'}
    >
      {
        ArchiveArray.length > 0 && <DashboardNavbar selected={selected} />
      }
      
      <Flex
        w={ gridView ? '100%' : '80%'}
        flexWrap={'wrap'}
        h={'60vh'}
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        overflowY={'auto'}
      >
        {
          ArchiveArray.length > 0 ? (
            <>
              {ArchiveArray.map((item, index) => {
                return <Card key={index} item={item} index={index} gridView={gridView} />
              })}
            </>) : (
            <Box
            fontSize={'md'}
            h={'60vh'} 
            color={'black'} w={'100%'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            >No Archieve Properties</Box>
          )
        }
      </Flex>
    </VStack>

  )
}

export default ArchieveList
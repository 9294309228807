import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Signup from './components/auth/Signup';
import Login from './components/auth/Login';
import { AuthContext } from './context/AuthContext';
import { useContext } from 'react';
import Dashboard from './components/dashboard/Dashboard';
import Calender from './components/calender/Calender';
import Reports from './components/reports/Reports';
import Navbar from './components/common/Navbar';
import { Container, Text, Flex } from '@chakra-ui/react';
import background from './assets/background-home.png';
import Verification from './components/auth/Verification';
import PropertyForm from './components/dashboard/myproperties/PropertyForm';
import Forget from './components/auth/Forget';
import PropfilePopup from './components/common/PropfilePopup';
import Support from './components/support/Support';
import ForgetVerify from './components/auth/ForgetVerify';
import ResetPassword from './components/auth/ResetPassword';
import AddAppraisal from './components/dashboard/myorders/AddAppraisal';
import Footer from './components/common/Footer';

function App() {
  const { User, viewProfilePopup, loading } = useContext(AuthContext);

  if (loading) {
    return <Text pl={10} mt={5}>Loading...</Text>;
  }

  return (
    <Router>
      {User ? <PrivateRoutes viewProfilePopup={viewProfilePopup} /> : <PublicRoutes />}
    </Router>
  );
}
const PrivateRoutes = ({ viewProfilePopup }) => {
  return (
    <Container
      h={'100vh'}
      maxW={'100vw'}
      p={0}
      backgroundColor={'#F8FAFB'}
      w={'100%'}
      justifyContent={'space-between'}
      alignItems={'center'}
      backgroundImage={background}
      backgroundSize={'cover'}
      backgroundPosition={'center'}
      flexDirection={'column'}
      overflowY={'auto'}
    >
      <Flex
        minH={'10vh'}
        justifyContent={'center'}
        alignItems={'center'}>
        <Navbar />
      </Flex>
      <Flex justifyContent={'center'}
        alignItems={'center'}
        minH={'85vh'}
      >
        <Routes>
          <Route path='/' element={<Navigate to='/dashboard' />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/property-form' element={<PropertyForm />} />
          <Route path='/calender' element={<Calender />} />
          <Route path='/reports' element={<Reports />} />
          <Route path='/get-support' element={<Support />} />
          <Route path='/dashboard/order-appraisal' element={<AddAppraisal/>}/>
          <Route path='/order-appraisal' element={<AddAppraisal />} />
        </Routes>
      </Flex>
      <Flex justifyContent={'center'}
        alignItems={'center'}
        minH={'5vh'}
      >
        <Footer />
      </Flex>
      {viewProfilePopup && <PropfilePopup />}
    </Container>
  );
};

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path='/signup' element={<Signup />} />
      <Route path='/login' element={<Login />} />
      <Route path='/' element={<Navigate to='/login' />} />
      <Route path='/forget' element={<Forget />} />
      <Route path='/verification' element={<Verification />} />
      <Route path='/forget-verification' element={<ForgetVerify />} />
      <Route path='/reset' element={<ResetPassword />} />
    </Routes>
  );
};

export default App;

import React from 'react'
import { HStack, Text } from '@chakra-ui/react'
export default function Footer(){
  return (
    <HStack 
     w={'100%'} 
     justifyContent={'space-between'} 
     alignItems={'center'} 
     px={'100px'} 
     py={2}>
      <Text w={'75%'} fontSize={'11px'} color={'#656A72'}
      textAlign={'start'}
      >
        Nextday Desktop Valuations. All rights reserved.
      </Text>
      <Text w={'10%'} fontSize={'11px'} color={'#656A72'}
      textAlign={'end'}
      >
        Terms of Service
      </Text>
      <Text w={'10%'} fontSize={'11px'} color={'#656A72'}
      textAlign={'end'}
      >
        Privacy Policy
      </Text>
    </HStack>
  )
}

